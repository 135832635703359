import { Button, CircularProgress, Typography, styled } from '@material-ui/core';
import FileUploadIcon from '@material-ui/icons/PublicOutlined';
import { Alert } from '@material-ui/lab';
import { postData } from 'api/services';
import React, { useState } from 'react';

const HiddenInput = styled('input')({ display: 'none' });

const MIME_TYPE_EXCEL =
  'application/vnd.ms-excel;application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64';

const ManageClientsForm: React.FC = () => {
  const [sitesFile, setSitesFile] = useState<string | null>(null);
  const [sitesFileName, setSitesFileName] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const canSubmit = sitesFile !== null;

  const clearForm = () => {
    setSitesFile(null);
    setSitesFileName(null);
    setIsSubmitting(false);
  };

  const clearStatus = () => {
    setSubmitSuccess(null);
    setSubmitError(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!sitesFile) return;
    setIsSubmitting(true);
    const headers = new Headers();
    headers.append('Content-Type', MIME_TYPE_EXCEL);
    try {
      const response = await postData<any>(`manage/sites`, sitesFile, null, headers);
      const insertedSites = response?.data?.body?.insertedCount || '';
      clearForm();
      setSubmitSuccess(`Successfully uploaded ${insertedSites} sites`);
    } catch (error) {
      console.log('error', error.message);
      clearForm();
      setSubmitError('Error uploading sites');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="excel-file">
        <HiddenInput
          type="file"
          accept={MIME_TYPE_EXCEL}
          id="excel-file"
          onChange={e => {
            if (!e.target.files?.length) return;
            if (!e.target.files[0]?.name) return;

            const reader = new FileReader();

            reader.onload = async file => {
              try {
                if (!reader.result) throw new Error('No file found');

                const base64String = (reader.result as string)
                  .replace('data:', '')
                  .replace(/^.+,/, '');
                setSitesFile(base64String);
              } catch (e) {
                console.log('reader onload error');
                console.error(e);
                clearForm();
                return;
              }
            };

            clearStatus();
            setSitesFileName(e.target.files[0]?.name);
            reader.readAsDataURL(e.target.files[0]);
          }}
        />
        <Button
          variant="text"
          component="span"
          size="small"
          startIcon={<FileUploadIcon />}
          // sx={{ mr: '-5px' }}
        >
          Upload sampling sites excel file
        </Button>
      </label>
      <Button
        type="submit"
        disabled={!canSubmit || isSubmitting}
        variant="contained"
        color="primary">
        submit
      </Button>
      <Typography>{sitesFileName}</Typography>
      {isSubmitting && (
        <Alert severity="info" icon={<CircularProgress size={20} />}>
          Adding new sampling sites...
        </Alert>
      )}
      {submitSuccess && <Alert severity="success">{submitSuccess}</Alert>}
      {submitError && <Alert severity="error">{submitError}</Alert>}
    </form>
  );
};

export default ManageClientsForm;
