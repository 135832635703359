import Box from '@material-ui/core/Box';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import React, { useState } from 'react';

export interface BaseMapControlProps {
  baseMapLayers: string[]; // An array of map style layer ids
  handleClick: (id: string) => void; // An optional callback function
}

export const BaseMapControl: React.FC<BaseMapControlProps> = ({
  baseMapLayers,
  handleClick,
}) => {
  const [current, setCurrent] = useState(1);

  const next = () => {
    setCurrent((current + 1) % baseMapLayers.length);
  };

  const switchBaseMap = () => {
    if (!(baseMapLayers.length > 1)) {
      return;
    }

    next();
    handleClick(baseMapLayers[current]);
  };

  return (
    <button onClick={switchBaseMap}>
      <Box
        height={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center">
        <LayersOutlinedIcon fontSize="small" />
      </Box>
    </button>
  );
};
