import { Layer } from 'mapbox-gl';

import { LayerProps } from './LayerProps';

const sampleSitesLayers = (id: string, { theme }: LayerProps): Layer[] => {
  const markers: Layer = {
    id,
    type: 'circle',
    paint: {
      'circle-radius': {
        base: 1.5,
        stops: [
          [4, 4],
          [14, 8],
          [22, 60],
        ],
      },
      'circle-color': [
        'case',
        ['boolean', ['feature-state', 'highlight'], false],
        theme.palette.highlight.main,
        [
          'match',
          ['get', 'status'],
          'APPROVED',
          theme.palette.status.approvedSite.main,
          'DROPPED OFF',
          theme.palette.status.droppedOffSample.main,
          'PICKED UP',
          theme.palette.status.pickedUpSample.main,
          'DO NOT SAMPLE',
          theme.palette.status.doNotSample.main,
          theme.palette.grey['500'], // Other
        ],
      ],
      'circle-stroke-width': {
        base: 1.5,
        stops: [
          [8, 1],
          [16, 2],
          [22, 3],
        ],
      },
      'circle-stroke-color': [
        'case',
        ['boolean', ['feature-state', 'highlight'], false],
        theme.palette.highlight.dark,
        [
          'match',
          ['get', 'status'],
          'APPROVED',
          theme.palette.status.approvedSite.dark,
          'DROPPED OFF',
          theme.palette.status.droppedOffSample.dark,
          'PICKED UP',
          theme.palette.status.pickedUpSample.dark,
          'DO NOT SAMPLE',
          theme.palette.status.doNotSample.dark,
          theme.palette.grey['800'], // Other
        ],
      ],
      'circle-opacity': 0.9,
      'circle-stroke-opacity': 0.9,
    },
    layout: {},
  };

  const label: Layer = {
    id: `${id}-label`,
    type: 'symbol',
    minzoom: 16,
    paint: {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'highlight'], false],
        theme.palette.highlight.contrastText,
        [
          'match',
          ['get', 'status'],
          'Approved Site',
          theme.palette.status.approvedSite.contrastText,
          'Dropped Off Sample',
          theme.palette.status.droppedOffSample.contrastText,
          'Picked Up Sample',
          theme.palette.status.pickedUpSample.contrastText,
          'Do Not Sample',
          theme.palette.status.doNotSample.contrastText,
          theme.palette.grey['100'], // Other
        ],
      ],
      'text-opacity': 0.8,
    },
    layout: {
      'text-optional': true,
      'text-field': ['get', 'lcr'],
      'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
      'text-size': ['interpolate', ['linear'], ['zoom'], 16, 11, 22, 24],
    },
  };

  return [markers, label];
};

export default sampleSitesLayers;
