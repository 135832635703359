import DayJSUtils from '@date-io/dayjs';
import { Button, makeStyles } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Dayjs } from 'dayjs';
import { SampleSite, SampleSiteSubmitKind } from 'interfaces';
import React, { useState } from 'react';

export type WQPPickedUpSubmit = Pick<SampleSite, 'logDate'> & { kind: SampleSiteSubmitKind };

interface PickUpFormProps {
  value: number;
  index: number;
  onSubmit: ({ kind, logDate }: WQPPickedUpSubmit) => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      '&:first-child': {
        marginTop: theme.spacing(2),
      },
      '&:last-child': {
        marginTop: theme.spacing(3),
      },
    },
  },
}));

export const WQPPickUpForm: React.FC<PickUpFormProps> = ({ value, index, onSubmit }) => {
  const classes = useStyles();
  const [logDate, setLogDate] = useState<Dayjs | null>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!logDate) return;
    const newSampleSiteInfo = {
      kind: SampleSiteSubmitKind.PickedUp,
      logDate: logDate.format(),
    };
    onSubmit(newSampleSiteInfo);
  };

  return (
    <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
      <div className={classes.container} hidden={value !== index}>
        {value === index && (
          <>
            <MuiPickersUtilsProvider utils={DayJSUtils}>
              <DateTimePicker
                required
                value={logDate}
                disableFuture
                onChange={setLogDate}
                label="Pick Up Time"
                format="MM/DD/YYYY h:mm a"
                showTodayButton
              />
            </MuiPickersUtilsProvider>
            <Button color="primary" variant="contained" disabled={!logDate} type="submit">
              Submit
            </Button>
          </>
        )}
      </div>
    </form>
  );
};
