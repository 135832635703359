import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AddNewScheduleResponse,
  GetSchedulesResponse,
  GetSystemResponse,
  PatchSystemResponse,
} from 'api';
import { fetchData, patchData, postData } from 'api/services';
import { Schedule, System } from 'interfaces';
import { RootState } from 'store';
import { constructErrorMessage } from 'utils/constructErrorMessage';

interface ManageSchedulesState {
  schedules: Schedule[];
  system: System | null;
  isLoading: boolean;
  isAddingNewSchedule: boolean;
  error: string | null;
}
const initialState: ManageSchedulesState = {
  error: null,
  isLoading: true,
  isAddingNewSchedule: false,
  system: null,
  schedules: [],
};

export const fetchSystem = createAsyncThunk(
  'manageSchedulesPage/fetchSystem',
  async (
    { clientIdentifier, system_id }: { clientIdentifier: string; system_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchData(`clients/${clientIdentifier}/systems/${system_id}`);
      return response.data as GetSystemResponse;
    } catch (err) {
      return rejectWithValue(constructErrorMessage(err.response));
    }
  }
);

export const fetchSchedules = createAsyncThunk(
  'manageSchedulesPage/fetchSchedules',
  async (
    { clientIdentifier, system_id }: { clientIdentifier: string; system_id: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchData(
        `clients/${clientIdentifier}/systems/${system_id}/schedules`
      );
      return response.data as GetSchedulesResponse;
    } catch (err) {
      return rejectWithValue(constructErrorMessage(err.response));
    }
  }
);

interface PatchSystem {
  system_id: string;
  changes: { isReady: boolean };
}

export const patchSystem = createAsyncThunk(
  'manageSchedulesPage/patchSystem',
  async ({ system_id, changes }: PatchSystem, { getState }) => {
    const { clientIdentifier } = (getState() as RootState).app;
    const response = await patchData(`clients/${clientIdentifier}/systems/${system_id}`, changes);
    return response.data as PatchSystemResponse;
  }
);

export const addNewSchedule = createAsyncThunk(
  'manageSchedulesPage/addNewSchedule',
  async (
    {
      clientIdentifier,
      system_id,
      analyteGroup,
      cycle,
      wqpPeriod,
      startDay,
      startMonth,
      startYear,
      reqSamples,
    }: {
      clientIdentifier: string;
      system_id: string;
      analyteGroup: string;
      cycle: string;
      wqpPeriod?: string;
      startDay: number;
      startMonth: number;
      startYear: number;
      reqSamples: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await postData(`manage/schedule`, null, {
        clientIdentifier,
        system_id,
        analyteGroup,
        cycle,
        wqpPeriod,
        startDay,
        startMonth,
        startYear,
        reqSamples,
      });
      return response.data as AddNewScheduleResponse;
    } catch (err) {
      return rejectWithValue(constructErrorMessage(err));
    }
  }
);

const manageSchedulesPageSlice = createSlice({
  name: 'manageSchedulesPage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSchedules.fulfilled, (state, action) => {
        state.schedules = action.payload.data;
        state.isLoading = false;
      })
      .addCase(fetchSystem.fulfilled, (state, action) => {
        state.system = action.payload.data;
      })
      .addCase(patchSystem.pending, state => {
        state.system = null;
      })
      .addCase(patchSystem.fulfilled, (state, action) => {
        state.system = action.payload.data;
      })
      .addCase(addNewSchedule.pending, state => {
        state.isAddingNewSchedule = true;
      })
      .addCase(addNewSchedule.fulfilled, (state, action) => {
        state.schedules = [action.payload.data, ...state.schedules];
        state.isAddingNewSchedule = false;
      });
  },
});

export default manageSchedulesPageSlice.reducer;
