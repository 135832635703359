import DayJSUtils from '@date-io/dayjs';
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CollapseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs from 'dayjs';
import { SampleSite, SampleSiteSubmitKind } from 'interfaces';
import React, { useState } from 'react';

export type CorrectionSubmit = Pick<
  SampleSite,
  'logDate' | 'faucetCollectionDate' | 'faucetLastUsedDate' | 'faucetLocation'
> & { kind: SampleSiteSubmitKind };

interface CorrectionFormProps {
  currentSampleSite: SampleSite;
  value: number;
  index: number;
  onSubmit: ({
    kind,
    logDate,
    faucetCollectionDate,
    faucetLastUsedDate,
    faucetLocation,
  }: CorrectionSubmit) => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(2),
      '&:first-child': {
        marginTop: theme.spacing(2),
      },
      '&:last-child': {
        marginTop: theme.spacing(3),
      },
    },
  },
}));

export const CorrectionForm: React.FC<CorrectionFormProps> = ({
  currentSampleSite,
  value,
  index,
  onSubmit,
}) => {
  const classes = useStyles();
  const [showLogDateCorrection, setShowLogDateCorrection] = useState(false);
  const [logDateCorrection, setLogDateCorrection] = useState<any>(null);
  const [showFaucetCollectionDateCorrection, setShowFaucetCollectionDateCorrection] = useState(
    false
  );
  const [faucetCollectionDateCorrection, setFaucetCollectionDateCorrection] = useState<any>(null);
  const [showFaucetLastUsedDateCorrection, setShowFaucetLastUsedDateCorrection] = useState(false);
  const [faucetLastUsedDateCorrection, setFaucetLastUsedDateCorrection] = useState<any>(null);
  const [showFaucetLocationCorrection, setShowFaucetLocationCorrection] = useState(false);
  const [faucetLocationCorrection, setFaucetLocationCorrection] = useState('');
  const {
    logDate: currentLogDate,
    faucetCollectionDate: currentFaucetCollectionDate,
    faucetLastUsedDate: currentFaucetLastUsedDate,
    faucetLocation: currentFaucetLocation,
  } = currentSampleSite;
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newSampleSiteInfo = {
      kind: SampleSiteSubmitKind.Correction,
      ...(showLogDateCorrection && { logDate: logDateCorrection.format() }),
      ...(showFaucetCollectionDateCorrection && {
        faucetCollectionDate: faucetCollectionDateCorrection.format(),
      }),
      ...(showFaucetLastUsedDateCorrection && {
        faucetLastUsedDate: faucetLastUsedDateCorrection.format(),
      }),
      ...(showFaucetLocationCorrection && { faucetLocation: faucetLocationCorrection }),
    };
    onSubmit(newSampleSiteInfo);
  };

  const handleSetFaucetLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFaucetLocationCorrection(e.target.value);
  };

  const changesMade =
    (showLogDateCorrection && logDateCorrection) ||
    (showFaucetCollectionDateCorrection && faucetCollectionDateCorrection) ||
    (showFaucetLastUsedDateCorrection && faucetLastUsedDateCorrection) ||
    (showFaucetLocationCorrection && faucetLocationCorrection.length);

  return (
    <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
      <div className={classes.container} hidden={value !== index}>
        {value === index && (
          <>
            <CorrectionField
              fieldName="Pick Up Time:"
              onEdit={setShowLogDateCorrection.bind(null, !showLogDateCorrection)}
              currentValue={dayjs(currentLogDate).format('MM/DD/YYYY h:mm a')}
              showEdit={showLogDateCorrection}
              editComponent={
                <MuiPickersUtilsProvider utils={DayJSUtils}>
                  <DateTimePicker
                    fullWidth
                    value={logDateCorrection}
                    disableFuture
                    onChange={setLogDateCorrection}
                    label="New Pick Up Time"
                    format="MM/DD/YYYY h:mm a"
                    showTodayButton
                  />
                </MuiPickersUtilsProvider>
              }
            />
            <CorrectionField
              fieldName="Water Last Used:"
              onEdit={setShowFaucetLastUsedDateCorrection.bind(
                null,
                !showFaucetLastUsedDateCorrection
              )}
              currentValue={dayjs(currentFaucetLastUsedDate).format('MM/DD/YYYY h:mm a')}
              showEdit={showFaucetLastUsedDateCorrection}
              editComponent={
                <MuiPickersUtilsProvider utils={DayJSUtils}>
                  <DateTimePicker
                    fullWidth
                    value={faucetLastUsedDateCorrection}
                    disableFuture
                    onChange={setFaucetLastUsedDateCorrection}
                    label="New Water Last Used Date"
                    format="MM/DD/YYYY h:mm a"
                    showTodayButton
                  />
                </MuiPickersUtilsProvider>
              }
            />
            <CorrectionField
              fieldName="Sample Collected:"
              onEdit={setShowFaucetCollectionDateCorrection.bind(
                null,
                !showFaucetCollectionDateCorrection
              )}
              currentValue={dayjs(currentFaucetCollectionDate).format('MM/DD/YYYY h:mm a')}
              showEdit={showFaucetCollectionDateCorrection}
              editComponent={
                <MuiPickersUtilsProvider utils={DayJSUtils}>
                  <DateTimePicker
                    fullWidth
                    value={faucetCollectionDateCorrection}
                    disableFuture
                    onChange={setFaucetCollectionDateCorrection}
                    label="New Sample Collected Date"
                    format="MM/DD/YYYY h:mm a"
                    showTodayButton
                  />
                </MuiPickersUtilsProvider>
              }
            />
            <CorrectionField
              fieldName="Faucet Location:"
              onEdit={setShowFaucetLocationCorrection.bind(null, !showFaucetLocationCorrection)}
              currentValue={currentFaucetLocation}
              showEdit={showFaucetLocationCorrection}
              editComponent={
                <TextField
                  fullWidth
                  onChange={handleSetFaucetLocationChange}
                  value={faucetLocationCorrection}
                  label="New Faucet Location"
                />
              }
            />

            <Button color="primary" variant="contained" disabled={!changesMade} type="submit">
              Submit
            </Button>
          </>
        )}
      </div>
    </form>
  );
};

const CorrectionField: React.FC<any> = ({
  fieldName,
  onEdit,
  currentValue,
  showEdit,
  editComponent,
}) => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box mr={2}>
          <Typography color={showEdit ? 'textPrimary' : 'textSecondary'}>{fieldName}</Typography>
          <Box mt={0.5}>
            <Typography color={showEdit ? 'textPrimary' : 'textSecondary'}>
              {currentValue}
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={onEdit}>{showEdit ? <CollapseIcon /> : <EditIcon />}</IconButton>
      </Box>
      {showEdit && <Box mt={1}>{editComponent}</Box>}
      <Box mt={2}>
        <Divider />
      </Box>
    </Box>
  );
};
