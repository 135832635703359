import { Box, BoxProps, Typography } from '@material-ui/core';
import { System } from 'interfaces';
import React from 'react';

interface SystemHeaderProps extends BoxProps {
  system: Pick<System, 'name' | 'state' | 'systemNumber'>;
  size?: 'md' | 'sm';
}

export const SystemHeader: React.FC<SystemHeaderProps> = ({ system, size = 'md', ...boxProps }) => {
  const { name, state, systemNumber } = system;

  return (
    <Box {...boxProps}>
      <Typography variant={size === 'sm' ? 'h4' : 'h2'}>
        <Box
          component="span"
          color="secondary.dark"
          mr={1}
          mb={0.5}
          style={{ display: 'inline-block' }}>
          {name}
        </Box>
        <Typography component="span" variant={size === 'sm' ? 'h5' : 'h4'} color="textSecondary">
          {state}
          {systemNumber}
        </Typography>
      </Typography>
    </Box>
  );
};
