import DayJSUtils from '@date-io/dayjs';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { postBenchmarkFile } from 'shared-components/EditModal/editModalSlice';
import { RootState, useAppDispatch } from 'store';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(3),
      '&:first-child': {
        marginTop: theme.spacing(2),
      },
    },
  },
}));

export const CreateBlobForm: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const sampleSiteInfo = useSelector((state: RootState) => state.editModal.metaData);

  const [file, setFile] = useState<File | null>(null);
  const [date, setDate] = useState<Dayjs | null>(null);
  const handleChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!file || !date) {
      return;
    }
    dispatch(
      postBenchmarkFile({
        ...sampleSiteInfo,
        file,
        completed: date.format(),
      })
    );
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <MuiPickersUtilsProvider utils={DayJSUtils}>
        <DatePicker
          required
          value={date}
          disableFuture
          onChange={setDate}
          label="Date Completed"
          format="MM/DD/YYYY"
          showTodayButton
        />
      </MuiPickersUtilsProvider>
      <Button variant="outlined" color="primary" component="label">
        Upload File
        <input onChange={handleChange} type="file" style={{ display: 'none' }} />
      </Button>
      {file && <Typography>{file.name}</Typography>}
      <Button color="primary" variant="contained" disabled={!date || !file} type="submit">
        Submit
      </Button>
    </form>
  );
};
