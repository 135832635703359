import { Box, List, ListItem, ListItemText, Theme, makeStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { RootState, useAppDispatch } from 'store';

import ManageClientsForm from './ManageClientForm';
import { fetchClients } from './manageClientsPageSlice';

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    height: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(3.5),
    },
  },
}));

const ManageClientsPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const clients = useSelector((state: RootState) => state.manageClientsPage.clients);

  useEffect(() => {
    const clientsPromise = dispatch(fetchClients());
    return () => {
      clientsPromise.abort();
    };
  }, [dispatch]);

  if (!clients) {
    return (
      <Box py={3}>
        <div>loading...</div>
      </Box>
    );
  }

  return (
    <Box py={3}>
      <ManageClientsForm />
      <List>
        {clients.map(client => {
          return (
            <Link
              key={client._id}
              component={RouterLink}
              to={`/manage/${client.identifier}`}
              variant="subtitle1">
              <ListItem button>
                <ListItemText primary={client.name} secondary={client._id} />
                <img className={classes.logo} src={client.assets.logo?.url} alt="logo" />
              </ListItem>
            </Link>
          );
        })}
      </List>
    </Box>
  );
};

export default ManageClientsPage;
