import { Box, Button, InputAdornment, InputBase, makeStyles } from '@material-ui/core';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import ListIcon from '@material-ui/icons/List';
import SearchIcon from '@material-ui/icons/Search';
import { ScheduleStatusI } from 'interfaces';
import debounce from 'lodash.debounce';
import {
  applyTextSearch,
  clearAllFilters,
  setIsDisplayMap,
  setOnlyActiveStatusFilter,
  setOnlyPBCU3YFilter,
  setOnlyPBCU6MFilter,
  setOnlyPBCULCRRFilter,
} from 'pages/dashboard/dashboardPageSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store';

const useStyles = makeStyles(theme => ({
  input: {
    fontSize: `${15 / 16}rem`,
  },
  filters: {
    marginLeft: theme.spacing(-1),
    '& > button': {
      flex: '0 0 auto',
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(1),
      },
    },
  },
}));

export const SystemFilter: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filteringStatus = useSelector((s: RootState) => s.dashboardPage.filteringStatus);
  const filteringPBCU6M = useSelector((s: RootState) => s.dashboardPage.filteringPBCU6M);
  const filteringPBCU3Y = useSelector((s: RootState) => s.dashboardPage.filteringPBCU3Y);
  const filteringPBCULCRR = useSelector((s: RootState) => s.dashboardPage.filteringPBCULCRR);
  const isDisplayMap = useSelector((s: RootState) => s.dashboardPage.isDisplayMap);
  const clientIdentifier = useSelector((s: RootState) => s.app.clientIdentifier);

  const deboucedOnSearchChange = debounce((text: string) => dispatch(applyTextSearch(text)), 300);

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'stretch', md: 'center' }}>
      <Box
        mr={{ xs: 0, md: 3 }}
        mb={{ xs: 3, md: 0 }}
        flex="1 1 0%"
        display="flex"
        alignItems="center">
        <InputBase
          className={classes.input}
          placeholder="Search PWS Name or ID"
          fullWidth
          onChange={e => deboucedOnSearchChange(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Box>
      <Box className={classes.filters} display="flex" flexDirection="row" flexWrap="wrap">
        <Button
          color="primary"
          onClick={() =>
            filteringStatus !== ScheduleStatusI.Active
              ? dispatch(setOnlyActiveStatusFilter())
              : dispatch(clearAllFilters())
          }
          disableElevation
          variant={filteringStatus === ScheduleStatusI.Active ? 'contained' : 'outlined'}>
          Active
        </Button>
        <Button
          color="primary"
          onClick={() =>
            !filteringPBCULCRR ? dispatch(setOnlyPBCULCRRFilter()) : dispatch(clearAllFilters())
          }
          disableElevation
          variant={filteringPBCULCRR ? 'contained' : 'outlined'}>
          LCRR
        </Button>
        <Button
          color="primary"
          onClick={() =>
            !filteringPBCU6M ? dispatch(setOnlyPBCU6MFilter()) : dispatch(clearAllFilters())
          }
          disableElevation
          variant={filteringPBCU6M ? 'contained' : 'outlined'}>
          6 Month
        </Button>
        <Button
          color="primary"
          onClick={() =>
            !filteringPBCU3Y ? dispatch(setOnlyPBCU3YFilter()) : dispatch(clearAllFilters())
          }
          disableElevation
          variant={filteringPBCU3Y ? 'contained' : 'outlined'}>
          Reduced
        </Button>
        {clientIdentifier === 'lja' && (
          <>
            {isDisplayMap ? (
              <Button
                onClick={() => dispatch(setIsDisplayMap(false))}
                color="primary"
                variant="text"
                startIcon={<ListIcon />}>
                List
              </Button>
            ) : (
              <Button
                onClick={() => dispatch(setIsDisplayMap(true))}
                color="primary"
                variant="text"
                startIcon={<ImageSearchIcon />}>
                Map
              </Button>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
