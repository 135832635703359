import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import appReducer from './appSlice';
import dashboardPageReducer from './pages/dashboard/dashboardPageSlice';
import detailsPageReducer from './pages/details/detailsPageSlice';
import manageClientsPageReducer from './pages/manageClients/manageClientsPageSlice';
import manageSchedulesPageReducer from './pages/manageSchedules/manageSchedulesPageSlice';
import manageSystemsPageReducer from './pages/manageSystems/manageSystemsPageSlice';
import mapPageReducer from './pages/map/mapPageSlice';
import alertSnackbarReducer from './shared-components/AlertSnackbar/alertSnackbarSlice';
import appBarReducer from './shared-components/AppBar/appBarSlice';
import editModalReducer from './shared-components/EditModal/editModalSlice';

const url = new URL(window.location.href);
// NOTE: 'inframark' should be used for testing regular client with no edit privileges
const clientIdentifier = process.env.NODE_ENV === 'production' ? url.host.split('.')[0] : 'lja';
const store = configureStore({
  reducer: {
    app: appReducer,
    dashboardPage: dashboardPageReducer,
    mapPage: mapPageReducer,
    detailsPage: detailsPageReducer,
    editModal: editModalReducer,
    alertSnackbar: alertSnackbarReducer,
    appBar: appBarReducer,
    manageClientsPage: manageClientsPageReducer,
    manageSystemsPage: manageSystemsPageReducer,
    manageSchedulesPage: manageSchedulesPageReducer,
  },
  preloadedState: {
    app: { clientIdentifier },
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export default store;
