import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

interface ScheduleStatusCardSkeletonProps {
  classes?: { progressContainer?: string; benchmarkContainer?: string };
}

export const ScheduleStatusCardSkeleton: React.FC<ScheduleStatusCardSkeletonProps> = ({
  classes = {},
}) => (
  <Box display="flex" flexDirection="column">
    <Box py="10px">
      <Skeleton variant="text" component="h6" width={200} height={32} />
    </Box>
    <Box
      className={classes.progressContainer}
      mt={2}
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}>
      <Box flex="1 1 0%">
        <Skeleton variant="rect" height={40} width="100%" />
      </Box>
      <Box
        className={classes.benchmarkContainer}
        flex="1 1 0%"
        mt={{ xs: 2, sm: 0 }}
        ml={{ xs: 0, sm: 1 }}>
        <Skeleton variant="rect" height={40} width="100%" />
      </Box>
    </Box>
  </Box>
);
