import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store';

import { closeSnackbar } from './alertSnackbarSlice';

export const AlertSnackbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isOpen, alert, severity } = useSelector((state: RootState) => state.alertSnackbar);
  return (
    <Snackbar open={isOpen} autoHideDuration={1500} onClose={() => dispatch(closeSnackbar())}>
      <Alert variant="filled" onClose={() => dispatch(closeSnackbar())} severity={severity}>
        {alert}
      </Alert>
    </Snackbar>
  );
};
