import 'mapbox-gl/dist/mapbox-gl.css';

import { IControl } from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';

import { BaseMapControl as BaseMapControlComponent } from '../components';

export class BaseMapControl implements IControl {
  private baseMapLayers: string[]; // A list of map style layer ids
  private controlGroup: HTMLElement;
  private handleClick: (id: string) => void;

  constructor(baseMapLayers: string[], handleClick: (id: string) => void) {
    this.baseMapLayers = baseMapLayers;
    this.controlGroup = document.createElement('div');
    this.controlGroup.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this.handleClick = handleClick;
  }

  onAdd() {
    ReactDOM.render(
      <BaseMapControlComponent
        baseMapLayers={this.baseMapLayers}
        handleClick={this.handleClick}
      />,
      this.controlGroup
    );
    return this.controlGroup;
  }

  onRemove() {
    if (this.controlGroup.parentNode) {
      this.controlGroup.parentNode.removeChild(this.controlGroup);
    }
  }
}
