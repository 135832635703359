import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { GetSystemResponse, fetchData } from 'api';
import { Page, System } from 'interfaces';
import {
  deleteBenchmark,
  postBenchmarkFile,
  postBenchmarkUrl,
} from 'shared-components/EditModal/editModalSlice';
import { RootState } from 'store';
import { constructErrorMessage } from 'utils';

interface DetailsPageState {
  isLoading: boolean;
  error: string | null;
  system?: System;
}
const initialState: DetailsPageState = { isLoading: true, error: null };

export const fetchSystemDetails = createAsyncThunk(
  'detailsPage/fetchSystemDetails',
  async (system_id: string, { getState, rejectWithValue }) => {
    const { clientIdentifier } = (getState() as RootState).app;
    try {
      const response = await fetchData(`clients/${clientIdentifier}/systems/${system_id}`);
      return response.data as GetSystemResponse;
    } catch (err) {
      return rejectWithValue(constructErrorMessage((err as Record<string, unknown>).response));
    }
  }
);

const detailsPageSlice = createSlice({
  name: 'detailsPage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSystemDetails.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchSystemDetails.fulfilled, (state, action) => {
        state.error = null;
        state.system = action.payload.data;
        state.isLoading = false;
      })
      .addCase(fetchSystemDetails.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addMatcher(
        isAnyOf(postBenchmarkUrl.fulfilled, postBenchmarkFile.fulfilled, deleteBenchmark.fulfilled),
        (state, action) => {
          const { currentPage, schedule_id } = action.meta.arg;
          if (currentPage !== Page.Details || !state.system) {
            return state;
          }
          state.system.schedules = state.system.schedules.map(schedule => {
            if (schedule._id !== schedule_id) {
              return schedule;
            }
            return { ...schedule, benchmarks: action.payload.data };
          });
        }
      );
  },
});

export default detailsPageSlice.reducer;
