import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {
  BoundaryFeatureCollection,
  MultiPolygonGeometry,
  NewSiteFeatureCollection,
  SampleSite,
  SampleSiteFeaturePoint,
  SampleSitesFeatureCollection,
  Schedule,
  System,
} from 'interfaces';
import { numberIdFromHex } from 'utils';

dayjs.extend(isBetween);

export const constructBoundaryFeatureCollection = (
  boundary: MultiPolygonGeometry
): BoundaryFeatureCollection => ({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      id: 999999999999,
      geometry: {
        type: 'MultiPolygon',
        coordinates: boundary,
      },
    },
  ],
});

export const constructBoundaryFeatureCollections = (
  systems: System[]
): BoundaryFeatureCollection => {
  return systems.reduce(
    (acc, system, i) => {
      if (!system.isReady || !system.boundary) {
        return acc;
      }

      const requiresSampling = system.schedules.reduce((acc, schedule) => {
        const { endDate, startDate } = schedule;
        // checking if schedule is currently active
        if (
          dayjs().isBetween(
            `${startDate.year}-${startDate.month}-${startDate.day}`,
            `${endDate.year}-${endDate.month}-${endDate.day}`,
            null,
            '[]'
          )
        ) {
          if (schedule.pickedUpSamples < schedule.reqSamples) {
            return true;
          }
        }
        return acc;
      }, false);

      return {
        ...acc,
        features: [
          ...acc.features,
          {
            type: 'Feature',
            properties: { name: system.name, requiresSampling },
            id: `999999999999${i}`,
            geometry: {
              type: 'MultiPolygon',
              coordinates: system.boundary,
            },
          },
        ],
      };
    },
    { type: 'FeatureCollection', features: [] } as BoundaryFeatureCollection
  );
};

export const constructSamplesFeatureCollection = (
  schedule: Schedule
): SampleSitesFeatureCollection => {
  return {
    type: 'FeatureCollection',
    features: schedule.sampleSites.map(
      (sampleSite: SampleSite): SampleSiteFeaturePoint => {
        return {
          type: 'Feature',
          geometry: { type: 'Point', coordinates: [sampleSite.lng, sampleSite.lat] },
          properties: {
            ...sampleSite,
          },
          id: numberIdFromHex(sampleSite.site_id),
        };
      }
    ),
  };
};

export const constructNewSiteFeatureCollection = (
  site: {
    lat: number;
    lng: number;
  } | null
): NewSiteFeatureCollection => {
  return {
    type: 'FeatureCollection',
    features: site
      ? [
          {
            type: 'Feature',
            geometry: { type: 'Point', coordinates: [site.lng, site.lat] },
            properties: {},
            id: 1,
          },
        ]
      : [],
  };
};
