import { ScheduleCycle } from 'interfaces';

export const formatCycleKind = (cycle: ScheduleCycle, startMonth: number) => {
  switch (cycle) {
    case ScheduleCycle.SixMonth:
      if (startMonth === 1) {
        return '6M1';
      }

      if (startMonth === 7) {
        return '6M2';
      }

      return cycle;

    case ScheduleCycle.WQP:
      if (startMonth === 1) {
        return 'WQP1';
      }

      if (startMonth === 4) {
        return 'WQP2';
      }

      if (startMonth === 7) {
        return 'WQP3';
      }

      if (startMonth === 10) {
        return 'WQP4';
      }

      return cycle;

    default:
      return cycle;
  }
};
