import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AnalyteGroup, ScheduleCycle } from 'interfaces/Schedule';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AlertMessage } from 'shared-components';
import { RootState, useAppDispatch } from 'store';

import {
  addNewSchedule,
  fetchSchedules,
  fetchSystem,
  patchSystem,
} from './manageSchedulesPageSlice';

enum WqpPeriod {
  Quarter = 'Q',
  Year = 'Y',
}

enum WqpQuarter {
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
}

enum SixMonthPeriod {
  One = '6M1',
  Two = '6M2',
}

type ScheduleCycles = SixMonthPeriod | ScheduleCycle;
type WqpPeriods = WqpPeriod | WqpQuarter;

const minSamples = 0;

const ManageSchedulesPage: React.FC = () => {
  const { clientIdentifier, system_id } = useParams<{
    clientIdentifier: string;
    system_id: string;
  }>();
  const dispatch = useAppDispatch();
  const schedules = useSelector((state: RootState) => state.manageSchedulesPage.schedules);
  const system = useSelector((state: RootState) => state.manageSchedulesPage.system);
  const isLoading = useSelector((state: RootState) => state.manageSchedulesPage.isLoading);

  const [analyteGroup, setAnalyteGroup] = useState<AnalyteGroup>(AnalyteGroup.PBCU);
  const [cycle, setCycle] = useState<ScheduleCycles>(ScheduleCycle.LCRR);
  const [wqpPeriod, setWqpPeriod] = useState<WqpPeriods>(WqpQuarter.One);
  const [startMonth, setStartMonth] = useState(1);
  const [startYear, setStartYear] = useState(new Date().getFullYear());
  const [reqSamples, setReqSamples] = useState('');
  const [formError, setFormError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const schedulesPromise = dispatch(fetchSchedules({ clientIdentifier, system_id }));
    const systemPromise = dispatch(fetchSystem({ clientIdentifier, system_id }));
    return () => {
      schedulesPromise.abort();
      systemPromise.abort();
    };
  }, [dispatch, clientIdentifier, system_id]);

  if (isLoading) {
    return (
      <Box py={3}>
        <LinearProgress />
      </Box>
    );
  }

  const handleCycleChange = (cycle: ScheduleCycles) => {
    switch (cycle) {
      case SixMonthPeriod.One:
        setStartMonth(1);
        break;
      case SixMonthPeriod.Two:
        setStartMonth(7);
        break;
      case ScheduleCycle.ThreeYear:
        setStartMonth(6);
        break;
      case ScheduleCycle.WQP:
        setStartMonth(1);
        break;
      case ScheduleCycle.LCRR:
        setStartMonth(1);
        break;
      default:
        setStartMonth(1);
        break;
    }
    setCycle(cycle);
  };

  const handleWqpPeriodChange = (wqpPeriod: WqpPeriods) => {
    switch (wqpPeriod) {
      case WqpQuarter.One:
        setStartMonth(1);
        break;
      case WqpQuarter.Two:
        setStartMonth(4);
        break;
      case WqpQuarter.Three:
        setStartMonth(7);
        break;
      case WqpQuarter.Four:
        setStartMonth(10);
        break;
      case WqpPeriod.Year:
        setStartMonth(1);
        break;
      default:
        setStartMonth(1);
        break;
    }
    setWqpPeriod(wqpPeriod);
  };

  const clearForm = () => {
    setReqSamples('');
    setFormError(null);
    setIsSubmitting(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!reqSamples) {
      setFormError('no required samples provided');
      return <AlertMessage severity="error" message={'no required samples provided'} />;
    }

    setIsSubmitting(true);

    // map 6M1/6M2 back to 6M
    const cycleToSubmit =
      cycle === SixMonthPeriod.One || cycle === SixMonthPeriod.Two ? ScheduleCycle.SixMonth : cycle;

    // assume WQP is quarterly
    let wqpPeriodToSubmit = WqpPeriod.Quarter;

    if (wqpPeriod === WqpPeriod.Year) {
      wqpPeriodToSubmit = WqpPeriod.Year;
    }

    await dispatch(
      addNewSchedule({
        clientIdentifier,
        system_id,
        analyteGroup,
        cycle: cycleToSubmit,
        ...(cycleToSubmit === ScheduleCycle.WQP && { wqpPeriod: wqpPeriodToSubmit }),
        startMonth,
        startYear,
        startDay: 1,
        reqSamples: parseInt(reqSamples, 10) || minSamples,
      })
    );
    clearForm();
  };

  return (
    <Box py={3}>
      {system?.name && (
        <Box py={1}>
          <Typography variant="h3">{system?.name}</Typography>
          <Typography variant="h6">
            {clientIdentifier}・TX{system?.systemNumber}
          </Typography>
        </Box>
      )}
      <form onSubmit={handleSubmit}>
        <FormControl>
          <InputLabel>Analyte</InputLabel>
          <Select value={analyteGroup} onChange={e => setAnalyteGroup(e.target.value as any)}>
            <MenuItem value={AnalyteGroup.PBCU}>PBCU</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Cycle</InputLabel>
          <Select value={cycle} onChange={e => handleCycleChange(e.target.value as any)}>
            <MenuItem value={SixMonthPeriod.One}>6M1</MenuItem>
            <MenuItem value={SixMonthPeriod.Two}>6M2</MenuItem>
            <MenuItem value={ScheduleCycle.ThreeYear}>3Y</MenuItem>
            <MenuItem value={ScheduleCycle.WQP}>WQP</MenuItem>
            <MenuItem value={ScheduleCycle.LCRR}>LCRR</MenuItem>
          </Select>
        </FormControl>
        {cycle === ScheduleCycle.WQP && (
          <FormControl>
            <InputLabel>WQP Period</InputLabel>
            <Select value={wqpPeriod} onChange={e => handleWqpPeriodChange(e.target.value as any)}>
              <MenuItem value={WqpQuarter.One}>Q1</MenuItem>
              <MenuItem value={WqpQuarter.Two}>Q2</MenuItem>
              <MenuItem value={WqpQuarter.Three}>Q3</MenuItem>
              <MenuItem value={WqpQuarter.Four}>Q4</MenuItem>
              <MenuItem value={WqpPeriod.Year}>Y</MenuItem>
            </Select>
          </FormControl>
        )}
        <FormControl>
          <InputLabel>Start Month</InputLabel>
          <Select value={startMonth} onChange={e => setStartMonth(e.target.value as any)}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
              return (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Start Year</InputLabel>
          <Select value={startYear} onChange={e => setStartYear(e.target.value as any)}>
            {[2019, 2020, 2021, 2022, 2023, 2024, 2025].map(year => {
              return (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl>
          <TextField
            label="required samples"
            value={reqSamples}
            type="number"
            inputProps={{ min: minSamples }}
            onChange={e => {
              if (!e.target.value) {
                setReqSamples('');
                return;
              }
              const input = parseInt(e.target.value, 10);
              input < minSamples ? setReqSamples('') : setReqSamples(input as any);
            }}
            error={Boolean(formError)}
            helperText={formError ? formError.includes('sample') && formError : ''}
          />
        </FormControl>
        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
          Create new
        </Button>
      </form>
      {isSubmitting && (
        <Alert severity="info" icon={<CircularProgress size={20} />}>
          Adding new schedule...
        </Alert>
      )}
      {system ? (
        <FormControlLabel
          control={
            <Switch
              checked={system.isReady}
              onClick={() =>
                dispatch(patchSystem({ system_id, changes: { isReady: !system.isReady } }))
              }
              name="checkedA"
              color="primary"
            />
          }
          label="Is System Ready"
        />
      ) : null}
      <List>
        {schedules.map(schedule => {
          return (
            <ListItem key={schedule._id} button>
              <ListItemText primary={schedule.analyteGroup} secondary={schedule.cycle} />
              <ListItemText
                primary={schedule.reqSamples}
                secondary={`${schedule.startDate.month} / ${schedule.startDate.day} / ${schedule.startDate.year}`}
              />
              <ListItemText primary={schedule._id} />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default ManageSchedulesPage;
