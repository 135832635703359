import { Box, Chip, Typography, makeStyles } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import clsx from 'clsx';
import dayjs from 'dayjs';
import {
  Benchmark,
  BenchmarkNames,
  Schedule,
  ScheduleCycle,
  ScheduleDate,
  ScheduleStatusI,
} from 'interfaces';
import React from 'react';
import { formatCycleKind, humanReadeableAnalyte } from 'utils';

import { BenchmarkButton, ProgressBar, ScheduleMapButton } from './components';
import { MaterialClassification } from './components/MaterialClassification';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    '& > *': {
      flex: '0 1 auto',
      marginLeft: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
}));

const formatScheduleDate = (date: ScheduleDate) => `${date.month}/${date.day}/${date.year}`;
const formatWqpScheduleDate = (date: ScheduleDate) =>
  dayjs(`${date.month}-${date.day}-${date.year}`, 'M-D-YYYY').format('MMMM YYYY');

const benchmarksToButtons = (
  system_id: string,
  schedule_id: string,
  benchmarks: Schedule['benchmarks'],
  isLCRRCycle: boolean
): React.ReactNode[] => {
  if (isLCRRCycle) {
    return [
      assetToButton(
        system_id,
        schedule_id,
        BenchmarkNames.LCRRMap,
        'lcrrMap',
        'url',
        benchmarks?.lcrrMap
      ),
      assetToButton(
        system_id,
        schedule_id,
        BenchmarkNames.DetailedInventory,
        'detailedInventory',
        'blob',
        benchmarks?.detailedInventory
      ),
      assetToButton(
        system_id,
        schedule_id,
        BenchmarkNames.CompletedWithTCEQApproval,
        'completedWithTceqApproval',
        'blob',
        benchmarks?.completedWithTceqApproval
      ),
    ];
  }

  return [
    assetToButton(
      system_id,
      schedule_id,
      BenchmarkNames.TCEQSampleSites,
      'tceqSampleSites',
      'blob' as 'blob',
      benchmarks?.tceqSampleSites
    ),
    assetToButton(
      system_id,
      schedule_id,
      BenchmarkNames.SamplesAtLab,
      'samplesAtLab',
      'blob' as 'blob',
      benchmarks?.samplesAtLab
    ),
    assetToButton(
      system_id,
      schedule_id,
      BenchmarkNames.LabResults,
      'labResults',
      'blob' as 'blob',
      benchmarks?.labResults
    ),
    assetToButton(
      system_id,
      schedule_id,
      BenchmarkNames.PostedOnDWW,
      'postedOnDww',
      'url' as 'url',
      benchmarks?.postedOnDww
    ),
    assetToButton(
      system_id,
      schedule_id,
      BenchmarkNames.LCNProvided,
      'lcnProvided',
      'blob' as 'blob',
      benchmarks?.lcnProvided
    ),
    assetToButton(
      system_id,
      schedule_id,
      BenchmarkNames.TCEQVerifiedComplete,
      'tceqVerifiedComplete',
      'url' as 'url',
      benchmarks?.tceqVerifiedComplete
    ),
  ];
};

const wqpBenchmarksToButtons = (
  system_id: string,
  schedule_id: string,
  benchmarks: Schedule['benchmarks']
): React.ReactNode[] => {
  return [
    assetToButton(
      system_id,
      schedule_id,
      BenchmarkNames.SamplesAtLab,
      'samplesAtLab',
      'blob' as 'blob',
      benchmarks?.samplesAtLab
    ),
    assetToButton(
      system_id,
      schedule_id,
      BenchmarkNames.LabResults,
      'labResults',
      'blob' as 'blob',
      benchmarks?.labResults
    ),
  ];
};

function assetToButton(
  system_id: string,
  schedule_id: string,
  benchmarkName: BenchmarkNames,
  benchmarkType: string,
  kind: 'url' | 'blob',
  benchmark?: Benchmark
): React.ReactNode {
  return (
    <BenchmarkButton
      system_id={system_id}
      schedule_id={schedule_id}
      benchmarkType={benchmarkType}
      benchmarkName={benchmarkName}
      kind={kind}
      completionDate={benchmark?.completedOn}
      url={benchmark?.url}
    />
  );
}

interface ScheduleStatusCardProps {
  system_id: string;
  schedule: Schedule;
  hasMapButton?: boolean;
  classes?: {
    progressContainer?: string;
    benchmarkContainer?: string;
    materialClassificationsContainer?: string;
  };
}

export const ScheduleStatusCard: React.FC<ScheduleStatusCardProps> = ({
  system_id,
  schedule,
  hasMapButton = true,
  classes: elementClasses = {},
}) => {
  const classes = useStyles();

  const {
    _id: schedule_id,
    analyteGroup,
    cycle,
    startDate,
    endDate,
    status,
    reqSamples,
    droppedOffSamples,
    pickedUpSamples,
    benchmarks,
    materialClassifications,
  } = schedule;

  const isWQPCycle = cycle === ScheduleCycle.WQP;
  const isNotWQPCycle = cycle !== ScheduleCycle.WQP;
  const isLCRRCycle = cycle === ScheduleCycle.LCRR;

  let dates: string = '';

  if (isWQPCycle) dates = `${formatWqpScheduleDate(startDate)} - ${formatWqpScheduleDate(endDate)}`;
  else if (isLCRRCycle) dates = startDate.year.toString();
  else dates = `${formatScheduleDate(startDate)} - ${formatScheduleDate(endDate)}`;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box
          flex="1 1 0%"
          mr={2}
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap">
          <Box mr={1}>
            <Typography variant="subtitle1">{dates}</Typography>
          </Box>
          {isWQPCycle ? (
            <Typography color="textSecondary">{formatCycleKind(cycle, startDate.month)}</Typography>
          ) : (
            <Typography color="textSecondary">{`${humanReadeableAnalyte(
              analyteGroup
            )} ${formatCycleKind(cycle, startDate.month)}`}</Typography>
          )}
        </Box>
        <Chip
          icon={status === ScheduleStatusI.Upcoming ? <ScheduleIcon /> : undefined}
          label={status}
          color="secondary"
          variant="outlined"
        />
      </Box>
      <Box
        className={elementClasses.progressContainer}
        mt={2}
        display="flex"
        flexDirection={isLCRRCycle ? 'row' : { xs: 'column', sm: 'row' }}>
        {isNotWQPCycle && !isLCRRCycle && (
          <ProgressBar
            complete={pickedUpSamples}
            droppedOff={droppedOffSamples}
            total={reqSamples}
          />
        )}
        <Box
          className={clsx(classes.buttonContainer, elementClasses.benchmarkContainer)}
          display="flex"
          flexDirection="row"
          flex="0 0 auto"
          ml={isNotWQPCycle && !isLCRRCycle ? { xs: 0, sm: 1 } : undefined}
          mt={isLCRRCycle ? 0 : { xs: 2, sm: 0 }}>
          {hasMapButton && !isLCRRCycle && (
            <ScheduleMapButton system_id={system_id} schedule_id={schedule_id} />
          )}
          {isWQPCycle
            ? wqpBenchmarksToButtons(
                system_id,
                schedule_id,
                benchmarks
              ).map((BenchmarkButton, i: number) => (
                <React.Fragment key={i}>{BenchmarkButton}</React.Fragment>
              ))
            : benchmarksToButtons(
                system_id,
                schedule_id,
                benchmarks,
                isLCRRCycle
              ).map((BenchmarkButton, i: number) => (
                <React.Fragment key={i}>{BenchmarkButton}</React.Fragment>
              ))}
        </Box>
      </Box>
      {Boolean(materialClassifications?.length) && (
        <Box className={elementClasses.materialClassificationsContainer} mt={3}>
          <Typography>Entire Service Line Material Classification</Typography>
          <Box mt={2}>
            <MaterialClassification
              items={
                materialClassifications as NonNullable<Schedule['materialClassifications']>
              }></MaterialClassification>
          </Box>
        </Box>
      )}
    </Box>
  );
};
