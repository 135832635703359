import Box, { BoxProps } from '@material-ui/core/Box';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { SampleSite, SampleSiteStatus } from 'interfaces/SampleSite';
import React from 'react';
import statusToThemeColor from 'utils/statusToThemeColor';

const useStyles = makeStyles<Theme, { status: SampleSiteStatus }>(theme => ({
  root: {
    '& > * + p': {
      marginTop: theme.spacing(1),
    },
  },
  title: {
    color: props => statusToThemeColor(theme, props.status).dark,
  },
}));

interface SampleSiteListItemProps extends BoxProps {
  site: SampleSite;
  isFocused: boolean;
}

export const SampleSiteListItem: React.FC<SampleSiteListItemProps> = ({
  site,
  isFocused,
  ...boxProps
}) => {
  const {
    address,
    lcr,
    status,
    user,
    kind,
    logDate,
    faucetCollectionDate,
    faucetLastUsedDate,
    faucetLocation,
  } = site;
  const classes = useStyles({ status });

  dayjs.extend(relativeTime);

  return (
    <Box className={classes.root} {...boxProps}>
      <Typography className={classes.title} variant="h6">
        {status} - {kind} {lcr && lcr}
      </Typography>
      <Typography>{address}</Typography>
      {logDate && (
        <Typography>{`${dayjs(logDate).format('MM/DD/YYYY')} (${user?.email})`}</Typography>
      )}
      {isFocused && (
        <>
          {faucetLastUsedDate && (
            <Typography>
              Water Last Used:
              <br />
              {dayjs(faucetLastUsedDate).format('MM/DD/YYYY h:mm A')} (
              {dayjs(faucetCollectionDate).from(faucetLastUsedDate, true)} prior)
            </Typography>
          )}
          {faucetCollectionDate && (
            <Typography>
              Sample Collected:
              <br />
              {dayjs(faucetCollectionDate).format('MM/DD/YYYY h:mm A')} ({faucetLocation})
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
export default SampleSiteListItem;
