import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { AllowedSiteEdits } from 'interfaces';
import { setEditMode } from 'pages/map/mapPageSlice';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { closeModal, setMetaData } from 'shared-components/EditModal/editModalSlice';
import { RootState, useAppDispatch } from 'store';

interface ManageFormProps {
  value: number;
  index: number;
  onSubmit: (changes: AllowedSiteEdits) => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      '&:first-child': {
        marginTop: theme.spacing(2),
      },
      '&:last-child': {
        marginTop: theme.spacing(3),
      },
    },
  },
}));

export const ManageForm: React.FC<ManageFormProps> = ({ value, index, onSubmit }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const {
    isActive,
    address,
    lng,
    lat,
    newLng,
    newLat,
    newIsActive,
    newAddress,
    eventMilestones,
  } = useSelector((state: RootState) => state.editModal.metaData);
  const [changes, setChanges] = useState<AllowedSiteEdits | null>(null);
  const [isRevert, setIsRevert] = useState(false);

  useEffect(() => {
    let changesObj = {};
    if (newLng !== undefined && newLat !== undefined && (newLng !== lng || newLat !== lat)) {
      changesObj = { ...changesObj, lng: newLng, lat: newLat };
    }
    if (newIsActive !== undefined && newIsActive !== isActive) {
      changesObj = { ...changesObj, isActive: newIsActive };
    }
    if (newAddress !== undefined && newAddress !== address) {
      changesObj = { ...changesObj, address: newAddress };
    }
    if (isRevert) {
      const appUser: { email: string; authId: string } = {
        email: user.email,
        authId: user.sub,
      };
      changesObj = { ...changesObj, revertingUser: { ...appUser } };
    }
    if (!Object.keys(changesObj).length) {
      setChanges(null);
      return;
    }
    setChanges(changesObj);
  }, [isActive, newIsActive, address, newAddress, lng, lat, newLng, newLat, isRevert, user]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!changes) return;
    onSubmit(changes);
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMetaData({ newAddress: e.target.value }));
  };

  const handleIsActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMetaData({ newIsActive: e.target.checked }));
  };

  const handleIsRevertChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsRevert(e.target.checked);
  };

  const handleSetSiteMode = () => {
    dispatch(closeModal({ saveStateonClose: true, reopenTab: index }));
    dispatch(setEditMode(true));
  };

  const [isTryingToDeleteSite, setIsTryingToDeleteSite] = useState(false);

  return (
    <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
      <div className={classes.container} hidden={value !== index}>
        {value === index && (
          <>
            {isTryingToDeleteSite ? (
              <>
                <Button
                  onClick={() => setIsTryingToDeleteSite(false)}
                  color="primary"
                  variant="contained">
                  No, Go Back
                </Button>
                <Typography color="error">
                  Are you sure you want to delete this site? All schedules using this site will be
                  affected.
                </Typography>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => onSubmit({ isDeleted: true })}>
                  Yes, Delete Site
                </Button>
              </>
            ) : (
              <>
                <Typography color="error">
                  WARNING: Making changes to a site will affect all schedules using the site
                </Typography>
                <Button onClick={handleSetSiteMode} variant="outlined" color="primary">
                  Change Site Location
                </Button>
                {newLng && <Typography>Longitude: {newLng}</Typography>}
                {newLat && <Typography>Latitude: {newLat}</Typography>}
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={newIsActive !== undefined ? newIsActive : isActive}
                      onChange={handleIsActiveChange}
                      name="Site isActive"
                    />
                  }
                  label={
                    (newIsActive !== undefined ? newIsActive : isActive)
                      ? 'Site status: "APPROVED"'
                      : 'Site status: "DO NOT SAMPLE"'
                  }
                />
                {eventMilestones !== undefined && (
                  <>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={isRevert}
                          onChange={handleIsRevertChange}
                          name="Sample revert"
                        />
                      }
                      label={isRevert ? 'Reverting Sample' : 'Revert Sample'}
                    />
                    {isRevert &&
                      eventMilestones.length === 1 &&
                      (newIsActive !== undefined ? newIsActive : isActive) && (
                        <Typography>Reverting to "APPROVED" Status</Typography>
                      )}
                    {isRevert &&
                      eventMilestones.length === 1 &&
                      (newIsActive !== undefined ? !newIsActive : !isActive) && (
                        <Typography>Reverting to "DO NOT SAMPLE" Status</Typography>
                      )}
                    {isRevert && eventMilestones.length === 2 && (
                      <>
                        <Typography>Reverting to "DROPPED OFF" Status</Typography>
                        <Typography>{`${dayjs(eventMilestones[0].logDate).format('MM/DD/YYYY')} (${
                          eventMilestones[0].user?.email
                        })`}</Typography>
                      </>
                    )}
                  </>
                )}

                <TextField
                  value={newAddress || address}
                  onChange={handleAddressChange}
                  label="Street Address"
                />
                <Button color="primary" variant="contained" disabled={!changes} type="submit">
                  Submit Changes
                </Button>
                <Button
                  onClick={() => setIsTryingToDeleteSite(true)}
                  color="secondary"
                  variant="contained"
                  disabled={!!changes}>
                  Delete Site
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </form>
  );
};
