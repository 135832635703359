import { Box, BoxProps } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

interface SystemHeaderSkeletonProps extends BoxProps {
  size?: 'md' | 'sm';
}

export const SystemHeaderSkeleton: React.FC<SystemHeaderSkeletonProps> = ({ size, ...props }) => (
  <Box {...props}>
    <Skeleton variant="text" component={size === 'sm' ? 'h4' : 'h2'} width="80%" />
  </Box>
);
