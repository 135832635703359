import { User } from './User';

export enum SampleSiteStatus {
  Approved = 'APPROVED',
  DroppedOff = 'DROPPED OFF',
  PickedUp = 'PICKED UP',
  DoNotSample = 'DO NOT SAMPLE',
}

export enum SampleSiteEditConditions {
  NewSite = 'NEW SITE',
}

export type SampleSiteConditions = SampleSiteStatus | SampleSiteEditConditions;

export enum SampleSiteSubmitKind {
  DroppedOff = 'DROPPED OFF',
  PickedUp = 'PICKED UP',
  Correction = 'CORRECTION',
}

export enum SampleSiteKind {
  LCR = 'LCR',
  EWQP = 'EWQP',
  DSTWQP = 'DSTWQP',
}

export interface SampleSite {
  site_id: string;
  // site active status, sampleSite can still have a status other than "DO NOT SAMPLE",
  // if samples were taken while site was active
  isActive: boolean;
  lcr?: string;
  kind: string;
  address: string;
  lat: number;
  lng: number;
  status: SampleSiteStatus;
  user?: User;
  logDate?: string;
  faucetCollectionDate?: string;
  faucetLastUsedDate?: string;
  // KITCHEN/BATHROOM
  faucetLocation?: string;
}

export type AllowedSiteEdits = Partial<Pick<SampleSite, 'isActive' | 'address' | 'lng' | 'lat'>> & {
  isDeleted?: boolean;
};
