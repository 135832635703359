import { Box, Button, IconButton, Menu, Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BusinessIcon from '@material-ui/icons/Business';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LinkIcon from '@material-ui/icons/Link';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MapIcon from '@material-ui/icons/Map';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { BenchmarkNames, FormKind, Page } from 'interfaces';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { initModal } from 'shared-components/EditModal/editModalSlice';
import { RootState, useAppDispatch } from 'store';

import { road } from '../ScheduleMapButton';

const useStyles = makeStyles(theme => ({
  button: {
    height: theme.spacing(5),
    minWidth: theme.spacing(5),
    width: '100%',
    padding: 0,
  },
  image: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: 2,
    border: `1px solid ${theme.palette.divider}`,
  },
  disabledButton: {
    height: theme.spacing(5),
    minWidth: theme.spacing(5),
    width: '100%',
    padding: 0,
    backgroundColor: theme.palette.action.hover,
    border: `1px solid ${theme.palette.divider}`,
  },
  label: {
    ...theme.typography.caption,
  },
  menu: {
    borderRadius: theme.spacing(3),
  },
  menuList: {
    padding: 0,
  },
  menuContent: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      borderRadius: 0,
      '&:first-child': {
        paddingTop: theme.spacing(2),
      },
      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
    },
  },
}));

const benchmarkIcons = {
  [BenchmarkNames.TCEQSampleSites]: LocationOnIcon,
  [BenchmarkNames.SamplesAtLab]: BusinessIcon,
  [BenchmarkNames.LabResults]: EqualizerIcon,
  [BenchmarkNames.PostedOnDWW]: FileCopyIcon,
  [BenchmarkNames.LCNProvided]: DescriptionIcon,
  [BenchmarkNames.TCEQVerifiedComplete]: AssignmentTurnedInIcon,
  [BenchmarkNames.LCRRMap]: MapIcon,
  [BenchmarkNames.DetailedInventory]: ListIcon,
  [BenchmarkNames.CompletedWithTCEQApproval]: AssignmentTurnedInIcon,
};

export interface BenchmarkButtonProps {
  system_id: string;
  schedule_id: string;
  benchmarkName: BenchmarkNames;
  benchmarkType: string;
  completionDate?: Date;
  url?: string;
  kind: 'url' | 'blob';
}

const checkCurrentPage = (pathname: string) => {
  if (pathname === '/') return Page.Dashboard;
  const splitPathname = pathname.split('/');
  if (splitPathname.length === 3 && splitPathname[1] === 'systems') return Page.Details;
  if (
    splitPathname.length === 5 &&
    splitPathname[1] === 'systems' &&
    splitPathname[3] === 'schedules'
  )
    return Page.Map;
  return null;
};

export const BenchmarkButton: React.FC<BenchmarkButtonProps> = ({
  system_id,
  schedule_id,
  benchmarkName,
  benchmarkType,
  kind,
  completionDate,
  url,
}) => {
  const dispatch = useAppDispatch();
  const clientIdentifier = useSelector((state: RootState) => state.app.clientIdentifier);
  const saveStateonClose = useSelector((state: RootState) => state.editModal.saveStateonClose);
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  dayjs.extend(relativeTime);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const isLCRRMap = benchmarkType === 'lcrrMap';

  const openMenu = (evt: any) => {
    setMenuAnchor(evt.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const handleOpenBenchmarkModal = useCallback(
    (formKind: FormKind) => {
      const currentPage = checkCurrentPage(location.pathname);
      if (!currentPage) return;
      dispatch(
        initModal({
          formKind,
          metaData: { system_id, schedule_id, benchmarkType, currentPage },
          title: benchmarkName,
        })
      );
      closeMenu();
    },
    [dispatch, system_id, schedule_id, benchmarkType, benchmarkName, location.pathname]
  );

  // Benchmark exists
  if (completionDate && url) {
    return (
      <>
        <Tooltip
          title={
            isLCRRMap ? 'LCRR Map' : `${benchmarkName} completed ${dayjs(completionDate).fromNow()}`
          }>
          {isLCRRMap ? (
            <React.Fragment>
              {clientIdentifier === 'lja' ? (
                <Button classes={{ root: classes.button, label: classes.label }} onClick={openMenu}>
                  <img className={classes.image} src={road} alt="map style" />
                </Button>
              ) : (
                <Button
                  classes={{ root: classes.button, label: classes.label }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={url}>
                  <img className={classes.image} src={road} alt="map style" />
                </Button>
              )}
            </React.Fragment>
          ) : (
            <Button
              classes={{ root: classes.button, label: classes.label }}
              color="primary"
              variant="contained"
              onClick={openMenu}>
              {dayjs(completionDate).format('MM/DD')}
            </Button>
          )}
        </Tooltip>
        <Menu
          classes={{ paper: classes.menu, list: classes.menuList }}
          anchorEl={menuAnchor}
          getContentAnchorEl={null}
          keepMounted
          open={!!menuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: -theme.spacing(1),
            horizontal: 'center',
          }}
          onClose={closeMenu}>
          <Box className={classes.menuContent}>
            <IconButton onClick={closeMenu}>
              <CloseIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={closeMenu} target="_blank" rel="noopener noreferrer" href={url}>
              <OpenInNewIcon fontSize="small" />
            </IconButton>
            {clientIdentifier === 'lja' && !saveStateonClose && (
              <IconButton onClick={handleOpenBenchmarkModal.bind(null, FormKind.DeleteForm)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Menu>
      </>
    );
  }

  // Benchmark does not exist
  let BenchmarkIcon = benchmarkIcons[benchmarkName] || HelpOutlineIcon;

  return (
    <>
      <Tooltip title={`${benchmarkName} not complete`}>
        <Button
          classes={{
            root: classes.disabledButton,
            label: classes.label,
          }}
          onClick={clientIdentifier === 'lja' && !saveStateonClose ? openMenu : undefined}>
          <BenchmarkIcon htmlColor={theme.palette.divider} />
        </Button>
      </Tooltip>
      <Menu
        classes={{ paper: classes.menu, list: classes.menuList }}
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        keepMounted
        open={!!menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -theme.spacing(1),
          horizontal: 'center',
        }}
        onClose={closeMenu}>
        <Box className={classes.menuContent}>
          <IconButton onClick={closeMenu}>
            <CloseIcon fontSize="small" />
          </IconButton>
          {kind === 'url' ? (
            <IconButton onClick={handleOpenBenchmarkModal.bind(null, FormKind.CreateUrlForm)}>
              <LinkIcon fontSize="small" />
            </IconButton>
          ) : (
            <IconButton onClick={handleOpenBenchmarkModal.bind(null, FormKind.CreateBlobForm)}>
              <CloudUploadIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Menu>
    </>
  );
};
