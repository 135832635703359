import { Box, Typography } from '@material-ui/core';
import React from 'react';

const PageNotFound = () => {
  return (
    <Box display="flex" justifyContent="center" mt={6}>
      <Typography variant="h5" component="h5">
        Page Not Found
      </Typography>
    </Box>
  );
};

export default PageNotFound;
