// Override default Mapbox styling to better suit our app

import { makeStyles } from '@material-ui/core/styles';

const addImage =
  // tslint:disable-next-line: max-line-length
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAQMAAABtzGvEAAAABlBMVEUAAAAAAAClZ7nPAAAAAXRSTlMAQObYZgAAABpJREFUeAFjoBZgPkB1yv7//z9YKVrYRyUAAGoSHy2hvqxVAAAAAElFTkSuQmCC';

const fullscreenImage =
  // tslint:disable-next-line: max-line-length
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgAQMAAADYVuV7AAAABlBMVEUAAAAzMzPI8eYgAAAAAXRSTlMAQObYZgAAACZJREFUeAFjAIFRwP//PwPD//8faM4BU/yDjjPMwGAOa3qmt1EAAJi6f4Gal950AAAAAElFTkSuQmCC';

const removeImage =
  // tslint:disable-next-line: max-line-length
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAKklEQVR4Ae3PAQkAAAzDsPs3/Ys4jAEJVECnHgAAAMCGezFgoBoAAADAAYiWb5Ec1zNuAAAAAElFTkSuQmCC';

const shrinkImage =
  // tslint:disable-next-line: max-line-length
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgAQMAAADYVuV7AAAABlBMVEUAAAAzMzPI8eYgAAAAAXRSTlMAQObYZgAAACNJREFUeAFjgIBR8B+IBieH/z+I8/8DLTnDCtAv3AZ72hkFAK3Sf4Hpvgm3AAAAAElFTkSuQmCC';

const useMapStyles = makeStyles(theme => ({
  '@global': {
    '.mapboxgl-map': {
      ...theme.typography.body1,
      '& canvas:focus': {
        outline: 0,
      },
    },
    '.mapboxgl-ctrl-group': {
      overflow: 'hidden',
      borderRadius: theme.shape.borderRadius,
      '& > button:focus': {
        boxShadow: 'none',
        '&:first-child': {
          borderRadius: 0,
        },
        '&:last-child': {
          borderRadius: 0,
        },
      },
    },
    '.mapboxgl-ctrl': {
      '&.mapboxgl-ctrl-attrib': {
        ...theme.typography.caption,
        lineHeight: 2,
        '& .mapbox-improve-map': {
          display: 'none',
        },
      },
    },
    'a.mapboxgl-ctrl-logo': {
      display: 'none',
    },
    '.mapboxgl-ctrl-icon': {
      backgroundPosition: 'center center',
      backgroundSize: '24px 24px',
      backgroundRepeat: 'no-repeat',
      '&.mapboxgl-ctrl-fullscreen': {
        backgroundImage: `url(${fullscreenImage})`,
      },
      '&.mapboxgl-ctrl-shrink': {
        backgroundImage: `url(${shrinkImage})`,
      },
      '&.mapboxgl-ctrl-zoom-in': {
        backgroundImage: `url(${addImage})`,
      },
      '&.mapboxgl-ctrl-zoom-out': {
        backgroundImage: `url(${removeImage})`,
      },
    },
  },
}));

export default useMapStyles;
