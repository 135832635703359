import { Button, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { deleteBenchmark } from 'shared-components/EditModal/editModalSlice';
import { RootState, useAppDispatch } from 'store';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
      '&:first-child': {
        marginTop: theme.spacing(2),
      },
    },
  },
}));

export const DeleteBenchmarkForm: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const sampleSiteInfo = useSelector((state: RootState) => state.editModal.metaData);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // NOTE: Do not remove currentPage, even though it is not used inside deleteBenchmark Thunk
    // it gets attached to the meta data and used once Promise is resolved
    dispatch(deleteBenchmark({ ...sampleSiteInfo }));
  };
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Typography>Are you sure you want to delete this benchmark?</Typography>
      <Button variant="contained" color="secondary" type="submit">
        Confirm Delete
      </Button>
    </form>
  );
};
