import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Theme, makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import {
  FormKind,
  SampleSiteEditConditions,
  SampleSiteFeaturePoint,
  SampleSiteKind,
  ScheduleCycle,
} from 'interfaces';
import React, { RefObject, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { initModal } from 'shared-components/EditModal/editModalSlice';
import { RootState, useAppDispatch } from 'store';

import { SampleSiteListItem, SampleSiteStatusIcon } from './components';

const useStyles = makeStyles((theme: Theme) => ({
  subheader: {
    ...theme.typography.h6,
    lineHeight: `${theme.spacing(6)}px`,
    background: theme.palette.background.default,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  listItem: {
    backgroundColor: theme.palette.background.paper,
  },
  focused: {
    backgroundColor: grey['100'],
  },
  newSiteHeader: {
    color: theme.palette.status.newSite.dark,
  },
  actionButton: {
    marginRight: '-2px',
  },
}));

interface SampleSitesListProps {
  onListItemSelect: (site: SampleSiteFeaturePoint) => void;
  focusedListItemId: number | null;
  system_id: string;
  schedule_id: string;
  systemName: string;
  scheduleCycle: ScheduleCycle;
}

export const SampleSitesList: React.FC<SampleSitesListProps> = ({
  onListItemSelect,
  focusedListItemId,
  system_id,
  schedule_id,
  systemName,
  scheduleCycle,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const focusedListItemIdRef = useRef(focusedListItemId);
  const clientIdentifier = useSelector((state: RootState) => state.app.clientIdentifier);
  const siteFeatures = useSelector(
    (state: RootState) => state.mapPage.sampleSiteFeatureCollection?.features
  );
  const saveStateonClose = useSelector((state: RootState) => state.editModal.saveStateonClose);

  const refs = siteFeatures?.reduce((acc: any, site) => {
    acc[site.id] = React.createRef();
    return acc;
  }, {} as Record<string, RefObject<undefined>>);

  useEffect(() => {
    if (!focusedListItemId || focusedListItemIdRef.current === focusedListItemId) return;
    refs[focusedListItemId]?.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    focusedListItemIdRef.current = focusedListItemId;
  }, [focusedListItemId, refs]);
  return (
    <List disablePadding>
      {clientIdentifier === 'lja' && (
        <React.Fragment key="new-site">
          <ListItem
            className={classes.listItem}
            button
            disabled={saveStateonClose}
            onClick={() => {
              dispatch(
                initModal({
                  title: `Creating new site for ${systemName}`,
                  formKind: FormKind.CreateNewSite,
                  metaData: {
                    system_id,
                    schedule_id,
                    newKind:
                      scheduleCycle === ScheduleCycle.WQP
                        ? SampleSiteKind.DSTWQP
                        : SampleSiteKind.LCR,
                  },
                })
              );
            }}>
            <ListItemAvatar>
              <SampleSiteStatusIcon status={SampleSiteEditConditions.NewSite} isFocused={false} />
            </ListItemAvatar>
            <Typography className={classes.newSiteHeader} variant="h6">
              Create new site
            </Typography>
          </ListItem>
          <Divider variant={'fullWidth'} />
        </React.Fragment>
      )}
      {siteFeatures?.map((site, i) => {
        const { properties, id } = site;
        const { status, kind, lcr } = properties;
        const isFocusedItem = focusedListItemId ? id === focusedListItemId : false;
        return (
          <React.Fragment key={i}>
            <ListItem
              ref={refs[id]}
              button
              className={clsx(classes.listItem, { [classes.focused]: isFocusedItem })}
              onClick={onListItemSelect.bind(null, site as SampleSiteFeaturePoint)}>
              <ListItemAvatar>
                <SampleSiteStatusIcon status={status} isFocused={isFocusedItem} />
              </ListItemAvatar>
              <SampleSiteListItem site={site.properties} isFocused={isFocusedItem} />
              {clientIdentifier === 'lja' && kind === 'LCR' && (
                <ListItemSecondaryAction>
                  <IconButton
                    className={classes.actionButton}
                    disabled={saveStateonClose}
                    onClick={() =>
                      dispatch(
                        initModal({
                          formKind: FormKind.EditSampleSite,
                          metaData: { ...properties, system_id, schedule_id },
                          title: `${kind}: ${lcr}`,
                        })
                      )
                    }
                    edge="end">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
              {clientIdentifier === 'lja' && kind !== 'LCR' && (
                <ListItemSecondaryAction>
                  <IconButton
                    className={classes.actionButton}
                    disabled={saveStateonClose}
                    onClick={() =>
                      dispatch(
                        initModal({
                          formKind: FormKind.EditSampleSite,
                          metaData: { ...properties, system_id, schedule_id },
                          title: kind,
                        })
                      )
                    }
                    edge="end">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            <Divider variant={i === siteFeatures.length - 1 ? 'fullWidth' : 'inset'} />
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default SampleSitesList;
