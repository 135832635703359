import { Theme } from '@material-ui/core/styles';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import {
  SampleSiteConditions,
  SampleSiteEditConditions,
  SampleSiteStatus,
} from 'interfaces/SampleSite';

const statusToThemeColor = ({ palette }: Theme, status: SampleSiteConditions): PaletteColor => {
  const map = {
    [SampleSiteStatus.Approved]: palette.status.approvedSite,
    [SampleSiteStatus.DroppedOff]: palette.status.droppedOffSample,
    [SampleSiteStatus.PickedUp]: palette.status.pickedUpSample,
    [SampleSiteStatus.DoNotSample]: palette.status.doNotSample,
    [SampleSiteEditConditions.NewSite]: palette.status.newSite,
    Other: palette.augmentColor({
      light: palette.grey['100'],
      main: palette.grey['500'],
      dark: palette.grey['800'],
    }),
  };

  return (status && map[status]) || map['Other'];
};

export default statusToThemeColor;
