import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

/**
 * Higher order component to require authenticating prior to rendering
 * @example
 * ```typescript
 * import { Auth0Provider } from '@auth0/auth0-react';
 * import { withAuth0Authenticated } from 'components';
 * import App from './components/App';
 * ReactDOM.render(
 *   <Auth0Provider
 *     domain="lja.auth0.com"
 *     clientId="CLIENT_ID"
 *     redirectUri={window.location.href.replace(window.location.hash, '')}
 *   >
 *     <AppWithAuth />
 *   </Auth0Provider>,
 *   document.getElementById('root')
 * );
 * ```
 */
export const withAuth0Authenticated = (WrappedComponent: any) => {
  return (props: any) => {
    const clientIdentifier = useSelector((state: RootState) => state.app.clientIdentifier);

    const { isLoading, loginWithRedirect, isAuthenticated, user } = useAuth0();

    // Each client (inframark, lja, etc...) has a specific auth0 database/connection.
    // A single Auth0 application for ljadrinkingwater.com allows all client connections.
    // User may be authenticated with a connection not for the current client identifier
    // if they sign in, then change applications (navigate to different client subdomain).
    // `isAuthenticated` is not enough to load app. User should be authenticated with the correct connection.
    // `https://ljadrinkingwater.com/connection` is a custom claim added via an Auth0 rule.
    const isAuthenticatedWithClient =
      isAuthenticated && user['https://ljadrinkingwater.com/connection'] === clientIdentifier;

    React.useEffect(() => {
      if (isLoading || isAuthenticatedWithClient) {
        return;
      }
      const fn = async () => {
        // TODO Handle invalid connection. No Auth0 connection for clientIdentifier.
        // If no connection, URL params includes `error=invalid_request&error_description=the%20connection%20is%20not%20enabled`
        await loginWithRedirect({ connection: clientIdentifier });
      };
      fn();
    }, [isLoading, isAuthenticatedWithClient, loginWithRedirect, clientIdentifier]);

    return isAuthenticatedWithClient ? <WrappedComponent {...props} /> : null;
  };
};
