import { Box, Button, CircularProgress, FormControl, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { ChangeEvent, useState } from 'react';
import { AlertMessage } from 'shared-components/AlertMessage';

interface ManageSystemsFormProps {
  onSubmit: (newSystemName: string, newSystemNumber: string) => Promise<void>;
}

const ManageSystemsForm: React.FC<ManageSystemsFormProps> = ({ onSubmit }) => {
  const [newSystemName, setNewSystemName] = useState('');
  const [newSystemNumber, setNewSystemNumber] = useState('');
  const [formError, setFormError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clearForm = () => {
    setNewSystemName('');
    setNewSystemNumber('');
    setFormError(null);
    setIsSubmitting(false);
  };

  const isValidSystemNumber = (systemNumber: string) => {
    if (!systemNumber) return false;
    return Boolean(systemNumber.match(/^TX[0-9]{7}$/));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newSystemName.length === 0) {
      setFormError('System name is required');
      return <AlertMessage severity="error" message={'system name required'} />;
    }
    if (!isValidSystemNumber(newSystemNumber)) {
      setFormError('Invalid system number');
      return;
    }
    setIsSubmitting(true);
    await onSubmit(newSystemName, newSystemNumber);
    clearForm();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" alignItems="center">
        <FormControl>
          <TextField
            label="New System Name"
            value={newSystemName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setNewSystemName(e.target.value)}
            error={Boolean(formError)}
            helperText={formError ? formError.includes('name') && formError : ''}
          />
        </FormControl>
        <FormControl>
          <TextField
            label="New System Number"
            placeholder="e.g. TX1234567"
            value={newSystemNumber}
            onChange={e =>
              !e.target.value ? setNewSystemNumber('') : setNewSystemNumber(e.target.value)
            }
            error={Boolean(formError)}
            helperText={formError ? formError.includes('number') && formError : ''}
          />
        </FormControl>
        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
          Add New System
        </Button>
      </Box>
      {isSubmitting && (
        <Alert severity="info" icon={<CircularProgress size={20} />}>
          Adding new system...
        </Alert>
      )}
    </form>
  );
};

export default ManageSystemsForm;
