import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { addNewSchedule } from 'pages/manageSchedules/manageSchedulesPageSlice';
import { addNewSystem } from 'pages/manageSystems/manageSystemsPageSlice';
import {
  deleteBenchmark,
  patchSite,
  postBenchmarkFile,
  postBenchmarkUrl,
  postNewSiteLocation,
  postSampleEvent,
} from 'shared-components/EditModal/editModalSlice';

interface AlertSnackbarState {
  isOpen: boolean;
  alert: string;
  severity: 'error' | 'success' | 'info' | 'warning' | undefined;
}
const initialState: AlertSnackbarState = { isOpen: false, alert: '', severity: undefined };

const alertSnackbarSlice = createSlice({
  name: 'alertSnackbar',
  initialState,
  reducers: {
    closeSnackbar: state => {
      state.isOpen = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(postSampleEvent.fulfilled, state => {
        state.alert = 'Sample successfully updated';
        state.severity = 'success';
        state.isOpen = true;
      })
      .addCase(postNewSiteLocation.fulfilled, state => {
        state.alert = 'Site successfully created';
        state.severity = 'success';
        state.isOpen = true;
      })
      .addCase(patchSite.fulfilled, state => {
        state.alert = 'Site successfully updated';
        state.severity = 'success';
        state.isOpen = true;
      })
      .addMatcher(
        isAnyOf(postBenchmarkUrl.fulfilled, postBenchmarkFile.fulfilled, deleteBenchmark.fulfilled),
        state => {
          state.alert = 'Benchmark successfully updated';
          state.severity = 'success';
          state.isOpen = true;
        }
      )
      .addMatcher(
        isAnyOf(
          postBenchmarkUrl.rejected,
          postBenchmarkFile.rejected,
          deleteBenchmark.rejected,
          postSampleEvent.rejected,
          addNewSystem.rejected,
          addNewSchedule.rejected,
          postNewSiteLocation.rejected,
          patchSite.rejected
        ),
        (state, action: any) => {
          state.alert = action.payload;
          state.severity = 'error';
          state.isOpen = true;
        }
      );
  },
});

export const { closeSnackbar } = alertSnackbarSlice.actions;

export default alertSnackbarSlice.reducer;
