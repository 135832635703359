import { useTheme } from '@material-ui/core';
import { BoundaryFeatureCollection } from 'interfaces';
import {
  CameraOptions,
  GeoJSONSource,
  GeolocateControl,
  Layer,
  Map as Mapbox,
  NavigationControl,
  PaddingOptions,
  Style,
} from 'mapbox-gl';
import { BaseMapControl } from 'pages/map/controls';
import React, { useEffect, useRef, useState } from 'react';

import districtsLayers from './layers/districtsLayers';
import useMapStyles from './useMapStyles';

const { REACT_APP_MAPBOX_TOKEN: accessToken } = process.env;

// Layers config
const districtsLayerId = 'districts';

// Base map styles
const mapStyles = [
  'mapbox://styles/ljagis/cknhso2n00exx17ms0fwa4wux?optimize=true',
  'mapbox://styles/ljagis/cklly7nho1xwj17pceyzstrje?optimize=true',
];

export type DivElementProps = Partial<
  Pick<React.HTMLAttributes<HTMLDivElement>, 'className' | 'style'>
>;

export type FlyToLocation = Partial<CameraOptions> & { padding?: number | Partial<PaddingOptions> };

export interface MapProps extends DivElementProps {
  boundaryFeatureCollection: BoundaryFeatureCollection;
}

export const Map: React.FC<MapProps> = ({ boundaryFeatureCollection, ...divElementProps }) => {
  useMapStyles();
  const theme = useTheme();

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const mapInstance = useRef<Mapbox>();
  const boundaryRef = useRef(boundaryFeatureCollection);
  const [baseMapStyle, setBaseMapStyle] = useState(mapStyles[0]);

  useEffect(() => {
    if (!mapContainer.current) return;

    const map = new Mapbox({
      container: mapContainer.current,
      accessToken,
      style: mapStyles[0],
      zoom: 9,
      center: [-95.366, 29.85],
      customAttribution: '© LJA Engineering, Inc.',
    });

    map.addControl(new NavigationControl(), 'top-right');
    map.addControl(
      new GeolocateControl({
        positionOptions: { enableHighAccuracy: true, timeout: 6000 },
        trackUserLocation: true,
      }),
      'top-right'
    );
    map.addControl(new BaseMapControl(mapStyles, setBaseMapStyle), 'top-right');

    const onStyleLoad = () => {
      const districtsSourceId = 'districts';
      map.addSource(districtsSourceId, { type: 'geojson', data: boundaryRef.current });
      districtsLayers(districtsLayerId, { theme }).forEach(l =>
        map.addLayer({ ...l, source: districtsSourceId }, placeLayerBefore(map.getStyle(), l.type))
      );
    };

    map.on('style.load', onStyleLoad);

    map.on('load', () => {
      // Save map instance ref for use elsewhere in the component
      mapInstance.current = map;
    });

    return () => {
      map.remove();
    };
  }, [theme]);

  useEffect(() => {
    if (!mapInstance.current) {
      return;
    }
    mapInstance.current.setStyle(baseMapStyle);
  }, [baseMapStyle, mapInstance]);

  /**  Redraw sample sites on change*/
  useEffect(() => {
    if (!mapInstance.current || !boundaryFeatureCollection) return;
    const map = mapInstance.current;
    const districtsSourceId = 'districts';
    const sampleSitesSource = map.getSource(districtsSourceId) as GeoJSONSource;

    sampleSitesSource.setData(boundaryFeatureCollection);

    // Save collection in ref for use when style is redrawn
    boundaryRef.current = boundaryFeatureCollection;
  }, [mapInstance, boundaryFeatureCollection]);

  return <div {...divElementProps} ref={mapContainer} />;
};

// export default Map;

/** id of best layer to insert a new layer of given type into a map style */
function placeLayerBefore(mapStyle: Style, type: Layer['type']): string | undefined {
  // Not exact since still 1 layer of type above, but close enough
  const layers = (mapStyle.layers || []).filter(l => l.type === type);
  return layers.length ? layers[layers.length - 1].id : undefined;
}
