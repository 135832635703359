import { Schedule, ScheduleCycle } from 'interfaces';

const cyclePriority = (cycle: ScheduleCycle) => {
  /**
   * Current priority:
   * 6M, 3Y, WQP
   */
  switch (cycle) {
    case ScheduleCycle.SixMonth:
      return 0;
    case ScheduleCycle.ThreeYear:
      return 1;
    default:
      return 9;
  }
};

export const sortYearlySchedules = (a: Schedule, b: Schedule) => {
  return cyclePriority(a.cycle) - cyclePriority(b.cycle) || a.startDate.month - b.startDate.month;
};

export const sortAllSchedules = (a: Schedule, b: Schedule) => {
  return (
    a.startDate.year - b.startDate.year ||
    cyclePriority(a.cycle) - cyclePriority(b.cycle) ||
    a.startDate.month - b.startDate.month
  );
};
