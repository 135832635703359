import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

export const SystemDetailsSkeleton: React.FC = () => {
  return (
    <Box py={3}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box flex="1 1 0%">
          <Skeleton variant="rect" width="80%" height={32} />
          <Box mt={1}>
            <Skeleton variant="rect" width="50%" height={32} />
          </Box>
          <Box mt={2}>
            <Skeleton variant="rect" width="65%" height={32} />
          </Box>
        </Box>
        <Box
          display={{ xs: 'none', sm: 'block' }}
          height={148}
          minWidth={{ sm: 320, md: 480 }}
          ml={3}>
          <Skeleton variant="rect" height="100%" width="100%" />
        </Box>
      </Box>
    </Box>
  );
};
