import { createSlice } from '@reduxjs/toolkit';

export interface AppState {
  clientIdentifier?: string;
}
const initialState: AppState = {};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
});

export default appSlice.reducer;
