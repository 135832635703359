import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

interface ProgressBarProps {
  complete: number;
  droppedOff: number;
  total: number;
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    height: theme.spacing(5),
    minHeight: theme.spacing(5),
    position: 'relative',
    display: 'flex',
    flex: '1 1 100%',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  inner: {
    position: 'absolute',
    top: 2,
    bottom: 2,
    left: 2,
    right: 2,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  completeBar: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.progress.complete.main,
  },
  droppedOffBar: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.progress.droppedOff.light,
    background: `repeating-linear-gradient(45deg, ${theme.palette.progress.droppedOff.light}, ${theme.palette.progress.droppedOff.light} 2px, rgba(0, 0, 0, 0.1) 2px, rgba(0, 0, 0, 0.1) 4px)`,
  },
  typography: {
    position: 'relative',
  },
}));

export const ProgressBar: React.FC<ProgressBarProps> = ({ complete, droppedOff, total }) => {
  const percentComplete = ((complete / total) * 100).toFixed(0);
  const percentDroppedOff = (((complete + droppedOff) / total) * 100).toFixed(0);

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.inner}>
        <Box className={classes.droppedOffBar} width={`${percentDroppedOff}%`}></Box>
        <Box className={classes.completeBar} width={`${percentComplete}%`}></Box>
      </Box>
      <Typography variant="caption" align="center" className={classes.typography}>
        {`${total} Req・${complete} Complete ${droppedOff ? `・${droppedOff} Dropped Off` : ''}`}
      </Typography>
    </Box>
  );
};
