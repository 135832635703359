import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  Box,
  FormControl,
  Link,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Select,
  Theme,
  Toolbar,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { RootState, useAppDispatch } from 'store';

import { changeCurrentSubClient, fetchClient, fetchSubClients } from './appBarSlice';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
  },
  flexView: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoLink: {
    display: 'flex',
  },
  logo: {
    height: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(4),
    },
  },
  subClientSelect: {
    margin: theme.spacing(0, 3),
  },
  selectInput: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:focus': { background: 0 },
  },
}));

export const AppBar: React.FC<{}> = () => {
  const classes = useStyles();

  const theme = useTheme();

  const dispatch = useAppDispatch();

  const { logout, user } = useAuth0();

  const { client, subClients, currentSubClient } = useSelector((state: RootState) => state.appBar);
  const clientIdentifier = useSelector((state: RootState) => state.app.clientIdentifier);

  const [menuAnchor, setMenuAnchor] = useState(null);

  useEffect(() => {
    const clientPromise = dispatch(fetchClient());
    let subClientPromise: ReturnType<AsyncThunkAction<any, any, any>> | null = null;

    if (clientIdentifier === 'lja') {
      subClientPromise = dispatch(fetchSubClients());
    }

    return () => {
      clientPromise.abort();
      subClientPromise && subClientPromise.abort();
    };
  }, [dispatch, clientIdentifier]);

  const openMenu = (evt: any) => {
    setMenuAnchor(evt.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const handleChangeSubClient = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(changeCurrentSubClient(event.target.value as string));
  };

  return (
    <MuiAppBar className={classes.root} position="fixed" color="default" variant="outlined">
      <Toolbar className={classes.flexView}>
        <Box>
          {Boolean(client?.assets.logo?.url) && (
            <Link className={classes.logoLink} component={RouterLink} to="/">
              <img className={classes.logo} src={client!.assets.logo!.url} alt="logo" />
            </Link>
          )}
        </Box>

        <Box flex={1} />

        {subClients && (
          <FormControl className={classes.subClientSelect}>
            <Select
              value={currentSubClient?.identifier}
              disableUnderline
              onChange={handleChangeSubClient}
              classes={{ select: classes.selectInput }}
              renderValue={clientIdentifier => {
                const identifier = (clientIdentifier as any).toString();
                const selectedClient = subClients.find(c => c.identifier === identifier);
                return selectedClient && selectedClient.assets.logo?.url ? (
                  <Box pr={1}>
                    <img
                      className={classes.logo}
                      src={selectedClient.assets.logo.url}
                      alt={identifier}
                    />
                  </Box>
                ) : (
                  identifier
                );
              }}>
              {subClients.map(client => (
                <MenuItem key={client.identifier} value={client.identifier}>
                  {client.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Box>
          <Avatar onClick={openMenu} style={{ cursor: 'pointer' }}>
            {user.email.substring(0, 2).toUpperCase()}
          </Avatar>
          <Menu
            id="simple-menu"
            anchorEl={menuAnchor}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: -theme.spacing(1), horizontal: 'center' }}
            keepMounted
            open={!!menuAnchor}
            onClose={closeMenu}>
            <MenuItem disabled>{user.email}</MenuItem>
            <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};
