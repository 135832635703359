import Avatar from '@material-ui/core/Avatar';
import { Theme, makeStyles } from '@material-ui/core/styles';
import BuildIcon from '@material-ui/icons/Build';
import OffIcon from '@material-ui/icons/InvertColorsOffOutlined';
import OnIcon from '@material-ui/icons/InvertColorsOutlined';
import {
  SampleSiteConditions,
  SampleSiteEditConditions,
  SampleSiteStatus,
} from 'interfaces/SampleSite';
import React from 'react';
import statusToThemeColor from 'utils/statusToThemeColor';

const useStyles = makeStyles<Theme, { status: SampleSiteConditions; isFocused: boolean }>(
  theme => ({
    avatar: {
      backgroundColor: props =>
        props.isFocused
          ? theme.palette.highlight.main
          : statusToThemeColor(theme, props.status).main,
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: props =>
        props.isFocused
          ? theme.palette.highlight.dark
          : statusToThemeColor(theme, props.status).dark,
    },
    icon: {
      color: props =>
        props.isFocused
          ? theme.palette.highlight.contrastText
          : statusToThemeColor(theme, props.status).contrastText,
      opacity: 0.5,
    },
  })
);

interface SampleSiteStatusIconProps {
  status: SampleSiteConditions;
  isFocused?: boolean;
}

export const SampleSiteStatusIcon: React.FC<SampleSiteStatusIconProps> = ({
  status,
  isFocused = false,
}) => {
  const classes = useStyles({ status, isFocused });
  return (
    <Avatar className={classes.avatar}>
      {status === SampleSiteStatus.DoNotSample ? (
        <OffIcon className={classes.icon} fontSize="small" />
      ) : status === SampleSiteEditConditions.NewSite ? (
        <BuildIcon className={classes.icon} fontSize="small" />
      ) : (
        <OnIcon className={classes.icon} fontSize="small" />
      )}
    </Avatar>
  );
};
