import { useTheme } from '@material-ui/core';
import * as turf from '@turf/turf';
import {
  BoundaryFeatureCollection,
  NewSiteFeatureCollection,
  SampleSitesFeatureCollection,
} from 'interfaces';
import {
  CameraOptions,
  GeoJSONSource,
  GeolocateControl,
  Layer,
  LngLatBoundsLike,
  Map as Mapbox,
  NavigationControl,
  PaddingOptions,
  Point,
  PointLike,
  Style,
} from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { BaseMapControl } from '../../controls';
import districtsLayers from './layers/districtsLayers';
import newSiteLayers from './layers/newSiteLayers';
import sampleSitesLayers from './layers/sampleSitesLayers';
import useMapStyles from './useMapStyles';

const { REACT_APP_MAPBOX_TOKEN: accessToken } = process.env;

// Layers config
const districtsLayerId = 'districts';
const sampleSitesLayerId = 'sample-sites';

// Base map styles
const mapStyles = [
  'mapbox://styles/ljagis/cknhso2n00exx17ms0fwa4wux?optimize=true',
  'mapbox://styles/ljagis/cklly7nho1xwj17pceyzstrje?optimize=true',
];

/** Pixels in each direction to query features from a map click location */
const clickBuffer = 5;

const selectableLayers: string[] = [sampleSitesLayerId];

export type DivElementProps = Partial<
  Pick<React.HTMLAttributes<HTMLDivElement>, 'className' | 'style'>
>;

export type FlyToLocation = Partial<CameraOptions> & { padding?: number | Partial<PaddingOptions> };

export interface MapProps extends DivElementProps {
  /** FeatureCollections to be added to map */
  sampleSiteFeatureCollection: SampleSitesFeatureCollection;
  boundaryFeatureCollection: BoundaryFeatureCollection;
  newSiteFeatureCollection: NewSiteFeatureCollection;
  /** Location to fly/zoom map */
  flyToLocation?: FlyToLocation;
  /**
   * Features to highlight on the map.
   * Features must be in a `selectableLayer` to be highlighted.
   */
  highlightFeature: number | null;
  /** Called when map is clicked with features in the `selectableLayer`s at the click location */
  onFeatureSelected: (featureId: number | null) => void;
  onMapSelected: any;
  isEditMode: boolean;
}

const Map: React.FC<MapProps> = ({
  sampleSiteFeatureCollection,
  boundaryFeatureCollection,
  newSiteFeatureCollection,
  flyToLocation,
  highlightFeature,
  onFeatureSelected,
  onMapSelected,
  isEditMode,
  ...divElementProps
}) => {
  useMapStyles();
  const theme = useTheme();

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const mapInstance = useRef<Mapbox>();
  const sampleSitesRef = useRef(sampleSiteFeatureCollection);
  const boundaryRef = useRef(boundaryFeatureCollection);
  const newSiteRef = useRef(newSiteFeatureCollection);
  const [baseMapStyle, setBaseMapStyle] = useState(mapStyles[0]);
  const [hasLoaded, setHasLoaded] = useState(false);

  const onMapClick = useCallback(
    ({ point }: { point: Point }) => {
      if (!onFeatureSelected || !selectableLayers.length || !mapInstance.current) return;

      const bbox: [PointLike, PointLike] = [
        [point.x - clickBuffer, point.y - clickBuffer],
        [point.x + clickBuffer, point.y + clickBuffer],
      ];

      const features = mapInstance.current.queryRenderedFeatures(bbox, {
        layers: selectableLayers,
      });
      if (!features.length) {
        onFeatureSelected(null);
        return;
      }
      // TODO: find furthest lat south and return
      const firstFeature = features[0];
      const featureId = firstFeature.id;
      if (!featureId) return;
      onFeatureSelected(featureId as number);
    },
    [onFeatureSelected, mapInstance]
  );

  const onEditModeClick = useCallback(
    ({ point }: { point: Point }) => {
      if (!onMapSelected || !selectableLayers.length || !mapInstance.current) return;

      onMapSelected(mapInstance.current.unproject(point));
    },
    [onMapSelected, mapInstance]
  );

  const onMouseMove = ({ point }: { point: Point }) => {
    if (!mapInstance.current) return;
    var features = mapInstance.current.queryRenderedFeatures(point, {
      layers: selectableLayers,
    });
    mapInstance.current.getCanvas().style.cursor = features.length ? 'pointer' : '';
  };

  const onEditModeMouseMove = () => {
    if (!mapInstance.current) return;
    mapInstance.current.getCanvas().style.cursor = 'crosshair';
  };

  useEffect(() => {
    if (!mapInstance.current || !hasLoaded) return;
    const map = mapInstance.current;
    if (isEditMode) {
      map.on('click', onEditModeClick);
      map.on('mousemove', onEditModeMouseMove);
    } else {
      map.on('mousemove', onMouseMove);
      map.on('click', onMapClick);
    }

    return () => {
      map.off('click', onEditModeClick);
      map.off('click', onMapClick);
    };
  }, [hasLoaded, isEditMode, mapInstance, onFeatureSelected, onMapClick, onEditModeClick]);

  useEffect(() => {
    if (!mapContainer.current) return;

    const bbox = turf.bbox(boundaryRef.current) as LngLatBoundsLike;
    const map = new Mapbox({
      container: mapContainer.current,
      accessToken,
      style: mapStyles[0],
      bounds: bbox,
      fitBoundsOptions: { padding: 20 },
      customAttribution: '© LJA Engineering, Inc.',
    });

    map.addControl(new NavigationControl(), 'top-right');
    map.addControl(
      new GeolocateControl({
        positionOptions: { enableHighAccuracy: true, timeout: 6000 },
        trackUserLocation: true,
      }),
      'top-right'
    );
    map.addControl(new BaseMapControl(mapStyles, setBaseMapStyle), 'top-right');

    const onStyleLoad = () => {
      const districtsSourceId = 'districts';
      map.addSource(districtsSourceId, { type: 'geojson', data: boundaryRef.current });
      districtsLayers(districtsLayerId, { theme }).forEach(l =>
        map.addLayer({ ...l, source: districtsSourceId }, placeLayerBefore(map.getStyle(), l.type))
      );

      const sampleSitesSourceId = 'sample-sites';
      map.addSource(sampleSitesSourceId, {
        type: 'geojson',
        data: sampleSitesRef.current,
      });
      sampleSitesLayers(sampleSitesSourceId, { theme }).forEach(l =>
        // Not using `placeLayerBefore`. Samples always on top of all other layers.
        map.addLayer({ ...l, source: sampleSitesSourceId })
      );

      const newSiteSourceId = 'new-site';
      map.addSource(newSiteSourceId, {
        type: 'geojson',
        data: newSiteRef.current,
      });
      newSiteLayers(newSiteSourceId, { theme }).forEach(l =>
        // Not using `placeLayerBefore`. New Site always on top of all other layers.
        map.addLayer({ ...l, source: newSiteSourceId })
      );
    };

    map.on('style.load', onStyleLoad);

    map.on('load', () => {
      // Save map instance ref for use elsewhere in the component
      mapInstance.current = map;
      setHasLoaded(true);
    });

    return () => {
      map.remove();
    };
  }, [onFeatureSelected, theme]);

  useEffect(() => {
    if (!mapInstance.current) {
      return;
    }
    mapInstance.current.setStyle(baseMapStyle);
  }, [baseMapStyle, mapInstance]);

  /**  Redraw sample sites on change*/
  useEffect(() => {
    if (!mapInstance.current || !sampleSiteFeatureCollection) return;
    const map = mapInstance.current;
    const sampleSitesSourceId = 'sample-sites';
    const sampleSitesSource = map.getSource(sampleSitesSourceId) as GeoJSONSource;

    sampleSitesSource.setData(sampleSiteFeatureCollection);

    // Save collection in ref for use when style is redrawn
    sampleSitesRef.current = sampleSiteFeatureCollection;
  }, [mapInstance, sampleSiteFeatureCollection]);

  useEffect(() => {
    if (!mapInstance.current || !newSiteFeatureCollection) return;
    const map = mapInstance.current;
    const sampleSitesSourceId = 'new-site';
    const sampleSitesSource = map.getSource(sampleSitesSourceId) as GeoJSONSource;

    sampleSitesSource.setData(newSiteFeatureCollection);

    // Save collection in ref for use when style is redrawn
    newSiteRef.current = newSiteFeatureCollection;
  }, [mapInstance, newSiteFeatureCollection]);

  useEffect(() => {
    if (flyToLocation) mapInstance.current?.flyTo({ ...flyToLocation, speed: 1.5 });
  }, [flyToLocation]);

  /** Apply `highlight` feature state to hightlight features */
  useEffect(() => {
    const map = mapInstance.current;
    // TODO: keep track with ref?
    if (!map || !highlightFeature) return;
    map.setFeatureState({ source: 'sample-sites', id: highlightFeature }, { highlight: true });
    return () => {
      map.getStyle() &&
        map.setFeatureState({ source: 'sample-sites', id: highlightFeature }, { highlight: false });
    };
  }, [highlightFeature, baseMapStyle, mapInstance]);

  return <div {...divElementProps} ref={mapContainer} />;
};

export default Map;

/** id of best layer to insert a new layer of given type into a map style */
function placeLayerBefore(mapStyle: Style, type: Layer['type']): string | undefined {
  // Not exact since still 1 layer of type above, but close enough
  const layers = (mapStyle.layers || []).filter(l => l.type === type);
  return layers.length ? layers[layers.length - 1].id : undefined;
}
