import { Button, MenuItem, Select, TextField, Typography, makeStyles } from '@material-ui/core';
import { SampleSiteKind } from 'interfaces';
import { setEditMode } from 'pages/map/mapPageSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  closeModal,
  postNewSiteLocation,
  setMetaData,
} from 'shared-components/EditModal/editModalSlice';
import { RootState, useAppDispatch } from 'store';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      '&:first-child': {
        marginTop: theme.spacing(2),
      },
      '&:last-child': {
        marginTop: theme.spacing(3),
      },
    },
  },
}));

export const CreateNewSiteForm: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const isLoading = useSelector((state: RootState) => state.editModal.isLoading);
  const { newLcr, newLng, newLat, system_id, schedule_id, newAddress, newKind } = useSelector(
    (state: RootState) => state.editModal.metaData
  );

  const isLcr = newKind === SampleSiteKind.LCR;

  const handleLcrChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMetaData({ newLcr: e.target.value }));
  };

  const handleWqpKindChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    dispatch(setMetaData({ newKind: e.target.value }));
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMetaData({ newAddress: e.target.value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      postNewSiteLocation({
        lcr: newLcr,
        kind: newKind,
        lng: newLng,
        lat: newLat,
        address: newAddress,
        system_id,
        schedule_id,
      })
    );
  };

  const handleSetSiteMode = () => {
    dispatch(closeModal({ saveStateonClose: true }));
    dispatch(setEditMode(true));
  };

  const isLcrSubmitDisabled =
    isLoading || newLcr?.length !== 3 || !newLng || !newLat || !newAddress?.length;
  const isWqpSubmitDisabled = isLoading || !newLng || !newLat || !newAddress?.length;

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate={false} autoComplete="off">
      <Button onClick={handleSetSiteMode} variant="outlined" color="primary">
        Set Site Location
      </Button>
      {newLng && <Typography>Longitude: {newLng}</Typography>}
      {newLat && <Typography>Latitude: {newLat}</Typography>}

      {isLcr && (
        <TextField
          value={newLcr || ''}
          onChange={handleLcrChange}
          required
          label="LCR"
          helperText="ex. 012 (3 digit)"
          error={newLcr?.length && newLcr?.length !== 3}
        />
      )}

      <TextField
        value={newAddress || ''}
        onChange={handleAddressChange}
        required
        label="Street Address"
        helperText="ex. 8112 Houston St"
      />

      {!isLcr && (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={newKind}
          onChange={handleWqpKindChange}>
          <MenuItem value={SampleSiteKind.EWQP}>{SampleSiteKind.EWQP}</MenuItem>
          <MenuItem value={SampleSiteKind.DSTWQP}>{SampleSiteKind.DSTWQP}</MenuItem>
        </Select>
      )}

      <Button
        color="primary"
        disabled={isLcrSubmitDisabled && isWqpSubmitDisabled}
        variant="contained"
        type="submit">
        Submit
      </Button>
    </form>
  );
};
