import { Box, Link, Theme, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { MultiPolygonGeometry, System } from 'interfaces';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { StaticOverlayMap } from 'shared-components';

const useStyles = makeStyles((theme: Theme) => ({
  mapContainer: {
    overflow: 'hidden',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
}));

interface BoundaryMapProps {
  boundary: MultiPolygonGeometry | null;
  path: string | undefined;
}

const BoundaryMap = React.forwardRef<HTMLDivElement, BoundaryMapProps>((props, ref) => {
  const classes = useStyles();
  return (
    <div {...props} ref={ref}>
      <Box
        className={classes.mapContainer}
        display={{ xs: 'none', sm: 'block' }}
        height={148}
        minWidth={{ sm: 320, md: 480 }}
        ml={3}>
        {props.boundary ? (
          <StaticOverlayMap overlay={props.boundary} path={props.path} />
        ) : (
          <Box height={1} display="flex" justifyContent="center" alignItems="center">
            Boundary Missing
          </Box>
        )}
      </Box>
    </div>
  );
});

const WrappedMapWithTooltip = ({
  boundary,
  path,
  tooltip,
}: {
  boundary: MultiPolygonGeometry | null;
  path: string | undefined;
  tooltip: string;
}) => {
  return (
    <Tooltip title={tooltip}>
      <BoundaryMap boundary={boundary} path={path} />
    </Tooltip>
  );
};

interface SystemOverviewHeaderProps {
  system: Pick<
    System,
    '_id' | 'state' | 'systemNumber' | 'name' | 'boundary' | 'externalResources' | 'schedules'
  >;
  mapPath?: string | undefined;
  mapTooltip?: string | undefined;
  shouldLinkDetails?: boolean;
}

export const SystemOverviewHeader: React.FC<SystemOverviewHeaderProps> = ({
  system,
  mapPath,
  mapTooltip = '',
  shouldLinkDetails = false,
}) => {
  const { name, state, systemNumber, externalResources, boundary, _id } = system;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box flex="1 1 0%">
        <Typography variant="h1">{name}</Typography>
        <Box mt={1}>
          <Typography component="span" variant="h4" color="textSecondary">
            {state}
            {systemNumber}
          </Typography>
        </Box>
        <Box mt={2}>
          {shouldLinkDetails && (
            <Link component={RouterLink} to={`/systems/${_id}`} variant="subtitle1">
              System Details
            </Link>
          )}
          <Typography>
            <Link
              href={externalResources.dww}
              variant="subtitle1"
              target="_blank"
              rel="noopener noreferrer">
              TCEQ Drinking Water Watch
            </Link>
          </Typography>
        </Box>
      </Box>
      <WrappedMapWithTooltip boundary={boundary} path={mapPath} tooltip={mapTooltip} />
    </Box>
  );
};
