import { Box, Divider } from '@material-ui/core';
import React from 'react';

import { SystemFilter, SystemList } from './components';

const DashboardPage: React.FC = () => {
  return (
    <Box py={3}>
      <SystemFilter />
      <Box mt={3} mb={4}>
        <Divider />
      </Box>
      <SystemList />
    </Box>
  );
};

export default DashboardPage;
