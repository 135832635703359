import { useAuth0 } from '@auth0/auth0-react';
import { Tab, Tabs, makeStyles } from '@material-ui/core';
import { AllowedSiteEdits } from 'interfaces';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { patchSite, postSampleEvent } from 'shared-components/EditModal/editModalSlice';
import { RootState, useAppDispatch } from 'store';

import {
  CorrectionForm,
  CorrectionSubmit,
  DropOffForm,
  DropOffSubmit,
  PickUpForm,
  PickedUpSubmit,
  WQPPickUpForm,
  WQPPickedUpSubmit,
} from './forms';
import { ManageForm } from './forms/ManageForm';

type EditSampleSiteSubmit = DropOffSubmit | PickedUpSubmit | CorrectionSubmit | WQPPickedUpSubmit;

const useStyles = makeStyles(() => ({
  tab: {
    minWidth: 90,
    fontSize: 13,
  },
}));

export const EditSampleSiteForms: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const reopenTab = useSelector((state: RootState) => state.editModal.reopenTab);
  const [value, setValue] = React.useState(reopenTab || 0);
  const { user } = useAuth0();
  const sampleSiteInfo = useSelector((state: RootState) => state.editModal.metaData);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleFormSubmit = useCallback(
    (newSampleSiteInfo: EditSampleSiteSubmit) => {
      const appUser: { email: string; authId: string } = {
        email: user.email,
        authId: user.sub,
      };
      dispatch(
        postSampleEvent({
          system_id: sampleSiteInfo.system_id,
          schedule_id: sampleSiteInfo.schedule_id,
          site_id: sampleSiteInfo.site_id,
          newSampleSiteInfo,
          user: appUser,
        })
      );
    },
    [dispatch, sampleSiteInfo, user]
  );

  const handleManageSubmit = (changes: AllowedSiteEdits) => {
    dispatch(
      patchSite({
        system_id: sampleSiteInfo.system_id,
        schedule_id: sampleSiteInfo.schedule_id,
        site_id: sampleSiteInfo.site_id,
        changes,
      })
    );
  };

  // scheduleKind === WQP
  if (sampleSiteInfo.kind !== 'LCR') {
    switch (sampleSiteInfo.status) {
      case 'DO NOT SAMPLE':
        return (
          <>
            <TabsWrapper value={value} onChange={handleChange}>
              <Tab className={classes.tab} label="Manage" />
            </TabsWrapper>
            <ManageForm value={value} index={0} onSubmit={handleManageSubmit} />
          </>
        );
    }

    return (
      <>
        <TabsWrapper value={value} onChange={handleChange}>
          <Tab className={classes.tab} label="Pick Up" />
          <Tab className={classes.tab} label="Manage" />
        </TabsWrapper>
        <WQPPickUpForm value={value} index={0} onSubmit={handleFormSubmit} />
        <ManageForm value={value} index={1} onSubmit={handleManageSubmit} />
      </>
    );
  }

  // scheduleKind === LCR
  switch (sampleSiteInfo.status) {
    case 'APPROVED':
      return (
        <>
          <TabsWrapper value={value} onChange={handleChange}>
            <Tab className={classes.tab} label="Drop Off" />
            <Tab className={classes.tab} label="Pick Up" />
            <Tab className={classes.tab} label="Manage" />
          </TabsWrapper>
          <DropOffForm value={value} index={0} onSubmit={handleFormSubmit} />
          <PickUpForm value={value} index={1} onSubmit={handleFormSubmit} />
          <ManageForm value={value} index={2} onSubmit={handleManageSubmit} />
        </>
      );
    case 'DROPPED OFF':
      return (
        <>
          <TabsWrapper value={value} onChange={handleChange}>
            <Tab className={classes.tab} label="Pick Up" />
            <Tab className={classes.tab} label="Manage" />
          </TabsWrapper>
          <PickUpForm value={value} index={0} onSubmit={handleFormSubmit} />
          <ManageForm value={value} index={1} onSubmit={handleManageSubmit} />
        </>
      );
    case 'PICKED UP':
      return (
        <>
          <TabsWrapper value={value} onChange={handleChange}>
            <Tab className={classes.tab} label="Correction" />
            <Tab className={classes.tab} label="Manage" />
          </TabsWrapper>
          <CorrectionForm
            currentSampleSite={sampleSiteInfo}
            value={value}
            index={0}
            onSubmit={handleFormSubmit}
          />
          <ManageForm value={value} index={1} onSubmit={handleManageSubmit} />
        </>
      );
    default:
      // 'DO NOT SAMPLE'
      return (
        <>
          <TabsWrapper value={value} onChange={handleChange}>
            <Tab className={classes.tab} label="Manage" />
          </TabsWrapper>
          <ManageForm value={value} index={0} onSubmit={handleManageSubmit} />
        </>
      );
  }
};

interface TabsWrapperProps {
  children: React.ReactNode;
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const TabsWrapper: React.FC<TabsWrapperProps> = ({ children, value, onChange }) => {
  return (
    <Tabs
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
      value={value}
      onChange={onChange}>
      {children}
    </Tabs>
  );
};
