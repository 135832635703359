import { SampleSite } from 'interfaces';

import { Benchmark } from './Benchmark';
import { HasId } from './core';

export enum AnalyteGroup {
  PBCU = 'PBCU',
}

export enum ScheduleCycle {
  SixMonth = '6M',
  ThreeYear = '3Y',
  WQP = 'WQP',
  LCRR = 'LCRR',
}

export enum ScheduleStatusI {
  Upcoming = 'UPCOMING',
  Active = 'ACTIVE',
  Previous = 'PREVIOUS',
}

export interface ScheduleDate {
  year: number;
  month: number;
  day: number;
}

export interface Schedule extends HasId {
  analyteGroup: AnalyteGroup;
  cycle: ScheduleCycle;
  reqSamples: number;
  startDate: ScheduleDate;
  endDate: ScheduleDate;
  status: ScheduleStatusI;
  droppedOffSamples: number;
  pickedUpSamples: number;
  sampleSites: SampleSite[];
  benchmarks: {
    tceqSampleSites?: Benchmark;
    samplesAtLab?: Benchmark;
    labResults?: Benchmark;
    postedOnDww?: Benchmark;
    lcnProvided?: Benchmark;
    tceqVerifiedComplete?: Benchmark;
    lcrrMap?: Benchmark;
    detailedInventory?: Benchmark;
    completedWithTceqApproval?: Benchmark;
  };
  materialClassifications?: {
    classification:
      | 'Lead'
      | 'Galvanized Requiring Replacement'
      | 'Non-Lead'
      | 'Unknown'
      | 'Not Recorded';
    count: number;
  }[];
}

export enum ScheduleQueryOptions {
  Status = 'status',
}
