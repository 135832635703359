import { Box, Divider, List, ListItem, Theme, Typography, makeStyles } from '@material-ui/core';
import { System } from 'interfaces';
import React, { RefObject, createRef, useLayoutEffect } from 'react';
import { ScheduleStatusCard, SystemOverviewHeader } from 'shared-components';
import { sortAllSchedules } from 'utils';

const useStyles = makeStyles((theme: Theme) => ({
  mapContainer: {
    overflow: 'hidden',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  listItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& > div': {
      flex: '1 1 0%',
    },
  },
}));

const NoSchedules: React.FC = () => (
  <Box my={3}>
    <Typography variant="subtitle1" color="textSecondary">
      No sampling schedules available
    </Typography>
  </Box>
);

interface SystemDetailsProps {
  system: Pick<
    System,
    '_id' | 'state' | 'systemNumber' | 'name' | 'boundary' | 'externalResources' | 'schedules'
  >;
}

export const SystemDetails: React.FC<SystemDetailsProps> = ({ system }) => {
  const { schedules, _id: system_id } = system;
  const classes = useStyles();

  const sortedSchedules = [...schedules].sort(sortAllSchedules);

  const refs = sortedSchedules.reduce((acc, schedule) => {
    const year = schedule.startDate.year;
    if (acc[year]) {
      return acc;
    }
    acc[year] = createRef<HTMLDivElement>();
    return acc;
  }, {} as Record<string, RefObject<HTMLDivElement>>);

  useLayoutEffect(() => {
    if (!refs || sortedSchedules.length < 2) return;

    const currentYear = new Date().getFullYear();
    // check to see if current year is in sortedSchedules, otherwise next highest
    const closestSchedule = sortedSchedules.find(
      schedule => schedule.startDate.year >= currentYear
    );

    // if hasn't found one by now, use the latest year (largest value)
    const closestYear = !closestSchedule
      ? sortedSchedules[sortedSchedules.length - 1].startDate.year
      : closestSchedule.startDate.year;
    const targetYear = closestYear.toString();

    // NOTE: Giving time for components to render before trying to scrollIntoView
    const scrollAfterWait = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      refs[targetYear].current!.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    };
    scrollAfterWait();
  }, [refs, sortedSchedules]);

  return (
    <Box py={3}>
      <SystemOverviewHeader system={system} />
      <Box mt={4}>
        <Divider />
      </Box>
      <List>
        <>
          {sortedSchedules.length ? (
            sortedSchedules.map((schedule, i, schedules) => {
              // On new cycle
              if (i === 0 || schedule.cycle !== schedules[i - 1].cycle) {
                return (
                  <React.Fragment key={schedule._id}>
                    <Box mt={4} mb={2}>
                      <Typography
                        ref={refs[schedule.startDate.year.toString()]}
                        variant="h4"
                        color="secondary">
                        {schedule.startDate.year} {schedule.cycle}
                      </Typography>
                    </Box>
                    <ListItem className={classes.listItem} disableGutters>
                      <ScheduleStatusCard system_id={system_id} schedule={schedule} />
                    </ListItem>
                  </React.Fragment>
                );
              }
              // Same cycle
              return (
                <ListItem key={schedule._id} className={classes.listItem} disableGutters>
                  <ScheduleStatusCard system_id={system_id} schedule={schedule} />
                </ListItem>
              );
            })
          ) : (
            <NoSchedules />
          )}
        </>
      </List>
    </Box>
  );
};
