import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

interface AlertMessageProps {
  severity: 'error' | 'info' | 'success' | 'warning' | undefined;
  message: string;
}

export const AlertMessage: React.FC<AlertMessageProps> = ({ severity, message }) => {
  return (
    <Box mt={8} display="flex" flexDirection="column" alignItems="center">
      <Alert severity={severity} variant="outlined">
        {message}
      </Alert>
    </Box>
  );
};
