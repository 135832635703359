import { Box, Container, CssBaseline, ThemeProvider, Toolbar } from '@material-ui/core';
import DashboardPage from 'pages/dashboard/DashboardPage';
import DetailsPage from 'pages/details/DetailsPage';
import ManageClientsPage from 'pages/manageClients/ManageClientsPage';
import ManageSchedulesPage from 'pages/manageSchedules/ManageSchedulesPage';
import ManageSystemsPage from 'pages/manageSystems/ManageSystemsPage';
import MapPage from 'pages/map/MapPage';
import PageNotFound from 'pages/notfound/PageNotFound';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AlertSnackbar, AppBar, EditModal } from 'shared-components';
import theme from 'themes/theme';

const App: React.FC = () => (
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AlertSnackbar />
      <EditModal />
      <BrowserRouter>
        <AppBar />
        <Switch>
          <Route exact path="/">
            <Container>
              <Toolbar />
              <DashboardPage />
            </Container>
          </Route>
          <Route exact path={`/systems/:system_id/schedules/:schedule_id`}>
            <Box height="100vh" display="flex" flexDirection="column" overflow="hidden">
              <Toolbar />
              <MapPage flex="1 1 0%"></MapPage>
            </Box>
          </Route>
          <Route exact path={`/systems/:system_id`}>
            <Container>
              <Box display="flex" flexDirection="column" height="100vh">
                <Toolbar />
                <DetailsPage overflow="hidden" flex={1} />
              </Box>
            </Container>
          </Route>
          <Route exact path={`/manage/:clientIdentifier/systems/:system_id`}>
            <Container>
              <Toolbar />
              <ManageSchedulesPage />
            </Container>
          </Route>
          <Route exact path={`/manage/:clientIdentifier`}>
            <Container>
              <Toolbar />
              <ManageSystemsPage />
            </Container>
          </Route>
          <Route exact path={`/manage`}>
            <Container>
              <Toolbar />
              <ManageClientsPage />
            </Container>
          </Route>
          <Route path="*">
            <Container>
              <Toolbar />
              <PageNotFound />
            </Container>
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

export default App;
