import { green, red } from '@material-ui/core/colors';
import lime from '@material-ui/core/colors/lime';
import { Layer } from 'mapbox-gl';

import { LayerProps } from './LayerProps';

const districtsLayers = (id: string, { theme }: LayerProps): Layer[] => {
  const fill: Layer = {
    id,
    type: 'fill',
    paint: {
      'fill-color': [
        'case',
        ['boolean', ['feature-state', 'highlight'], false],
        theme.palette.highlight.light,
        ['case', ['boolean', ['get', 'requiresSampling'], true], red[900], green[900]],
      ],
      'fill-opacity': 0.5,
      'fill-outline-color': 'transparent',
    },
    layout: {},
  };

  const border: Layer = {
    id: `${id}-border`,
    type: 'line',
    paint: {
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'highlight'], false],
        theme.palette.highlight.dark,
        '#b3bc6d', // Lime 200 dark
      ],
      'line-width': {
        base: 1.5,
        stops: [
          [8, 0],
          [16, 5],
          [22, 60],
        ],
      },
      'line-dasharray': [2, 1],
    },
    layout: {
      'line-join': 'round',
    },
  };

  const label: Layer = {
    id: `${id}-label`,
    type: 'symbol',
    minzoom: 11.5,
    paint: {
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'highlight'], false],
        theme.palette.highlight.dark,
        lime['700'],
      ],
      'text-halo-blur': 1,
      'text-halo-color': 'hsl(0, 0%, 100%)',
      'text-halo-width': 1,
    },
    layout: {
      'text-field': ['get', 'name'],
      'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
      'text-size': ['interpolate', ['cubic-bezier', 0.2, 0, 0.9, 1], ['zoom'], 8, 10, 16, 20],
      'text-transform': 'uppercase',
    },
  };

  return [fill, border, label];
};

export default districtsLayers;
