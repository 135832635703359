import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  PatchSiteResponse,
  PostNewSiteLocationResponse,
  PostSampleEventResponse,
  UpdateBenchmarkResponse,
  deleteData,
  patchData,
  postData,
} from 'api';
import { AllowedSiteEdits, FormKind, Page, SampleSite, SampleSiteKind, User } from 'interfaces';
import { RootState } from 'store';

interface EditBenchmark {
  system_id: string;
  schedule_id: string;
  benchmarkType: string;
  currentPage: Page;
}

export const deleteBenchmark = createAsyncThunk(
  'editModal/deleteBenchmark',
  async ({ system_id, schedule_id, benchmarkType }: EditBenchmark, { getState }) => {
    const { clientIdentifier } = (getState() as RootState).app;
    const response = await deleteData(
      `clients/${clientIdentifier}/systems/${system_id}/schedules/${schedule_id}/benchmarks`,
      { type: benchmarkType }
    );
    return response.data as UpdateBenchmarkResponse;
  }
);
interface PostBenchmarkUrl extends EditBenchmark {
  url: string;
  completed: string;
}

export const postBenchmarkUrl = createAsyncThunk(
  'editModal/postBenchmarkUrl',
  async (
    { system_id, schedule_id, url, completed, benchmarkType }: PostBenchmarkUrl,
    { getState }
  ) => {
    const { clientIdentifier } = (getState() as RootState).app;
    const response = await postData(
      `clients/${clientIdentifier}/systems/${system_id}/schedules/${schedule_id}/benchmarks`,
      null,
      { url, completed, type: benchmarkType }
    );
    return response.data as UpdateBenchmarkResponse;
  }
);

interface PostBenchmarkFile extends EditBenchmark {
  file: File;
  completed: string;
}

export const postBenchmarkFile = createAsyncThunk(
  'editModal/postBenchmarkFile',
  async (
    { system_id, schedule_id, file, completed, benchmarkType }: PostBenchmarkFile,
    { getState }
  ) => {
    const { clientIdentifier } = (getState() as RootState).app;
    const formData = new FormData();
    formData.append('', file);
    const response = await postData(
      `clients/${clientIdentifier}/systems/${system_id}/schedules/${schedule_id}/benchmarks`,
      formData,
      { completed, type: benchmarkType }
    );
    return response.data as UpdateBenchmarkResponse;
  }
);

export const postNewSiteLocation = createAsyncThunk(
  'editModal/postNewSiteLocation',
  async (
    {
      system_id,
      schedule_id,
      kind,
      lcr,
      lng,
      lat,
      address,
    }: {
      system_id: string;
      schedule_id: string;
      lcr?: string;
      lng: number;
      lat: number;
      kind: string;
      address: string;
    },
    { getState }
  ) => {
    const { clientIdentifier } = (getState() as RootState).app;
    const response = await postData(
      `clients/${clientIdentifier}/systems/${system_id}/schedules/${schedule_id}/sites`,
      null,
      {
        lcr,
        kind,
        address,
        lng,
        lat,
      }
    );
    return response.data as PostNewSiteLocationResponse;
  }
);

interface PostSampleEvent {
  system_id: string;
  schedule_id: string;
  site_id: string;
  newSampleSiteInfo: any;
  user: User;
}

export const postSampleEvent = createAsyncThunk(
  'editModal/postSampleEvent',
  async (
    { system_id, schedule_id, site_id, newSampleSiteInfo, user }: PostSampleEvent,
    { getState }
  ) => {
    const { clientIdentifier } = (getState() as RootState).app;
    const response = await postData(
      `clients/${clientIdentifier}/systems/${system_id}/schedules/${schedule_id}/sites/${site_id}/sample-events`,
      {
        data: {
          type: 'sampleEvent',
          attributes: {
            user,
            ...newSampleSiteInfo,
          },
        },
      }
    );
    return response.data as PostSampleEventResponse;
  }
);

interface PatchSite {
  system_id: string;
  schedule_id: string;
  site_id: string;
  changes: AllowedSiteEdits;
}

export const patchSite = createAsyncThunk(
  'editModal/patchSite',
  async ({ system_id, schedule_id, site_id, changes }: PatchSite, { getState }) => {
    const { clientIdentifier } = (getState() as RootState).app;
    const response = await patchData(
      `clients/${clientIdentifier}/systems/${system_id}/schedules/${schedule_id}/sites/${site_id}`,
      changes
    );
    return response.data as PatchSiteResponse;
  }
);

interface EditModalState {
  isLoading: boolean;
  isModalOpen: boolean;
  title: string;
  formKind?: FormKind;
  metaData: any;
  // Typically Modal state is cleared after it's closed to display a smooth transition
  // saveStateonClose is used when other operations need to be performed between collecting information
  // (gathering site location), also used to disable functionality (don't want user trying to change sample
  // status midway through creating a new site).
  saveStateonClose: boolean;
  // If reopening modal after gathering additional information
  reopenTab?: number;
}
const initialState: EditModalState = {
  isLoading: false,
  isModalOpen: false,
  title: '',
  metaData: {},
  saveStateonClose: false,
};

const editModalSlice = createSlice({
  name: 'editModal',
  initialState,
  reducers: {
    initModal(state, action) {
      state.formKind = action.payload.formKind;
      state.metaData = action.payload.metaData || {};
      state.title = action.payload.title;
      state.isModalOpen = true;
    },
    setMetaData(state, action) {
      state.metaData = { ...state.metaData, ...action.payload };
    },
    closeModal: (state, action) => {
      state.saveStateonClose = action.payload.saveStateonClose;
      state.reopenTab = action.payload.reopenTab;
      state.isModalOpen = false;
    },
    openModal: state => {
      state.isModalOpen = true;
    },
    clearModal: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(postNewSiteLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isModalOpen = true;
        state.formKind = FormKind.EditSampleSite;
        const newSampleSite = action.payload.data.sampleSites.find(
          (sampleSite: SampleSite) => sampleSite.site_id === action.payload.meta.newSiteId
        );
        const { kind, lcr } = newSampleSite as SampleSite;
        state.title = kind === SampleSiteKind.LCR ? `${kind}: ${lcr}` : kind;
        state.metaData = {
          ...state.metaData,
          ...newSampleSite,
          newKind: undefined,
          newAddress: undefined,
          newLng: undefined,
          newLat: undefined,
        };
      })
      .addMatcher(
        isAnyOf(
          postBenchmarkUrl.pending,
          postBenchmarkFile.pending,
          deleteBenchmark.pending,
          postSampleEvent.pending,
          postNewSiteLocation.pending,
          patchSite.pending
        ),
        state => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          postBenchmarkUrl.fulfilled,
          postBenchmarkFile.fulfilled,
          deleteBenchmark.fulfilled,
          postSampleEvent.fulfilled,
          patchSite.fulfilled,
          postBenchmarkUrl.rejected,
          postBenchmarkFile.rejected,
          deleteBenchmark.rejected,
          postSampleEvent.rejected,
          postNewSiteLocation.rejected,
          patchSite.rejected
        ),
        state => {
          state.saveStateonClose = false;
          state.isModalOpen = false;
        }
      );
  },
});

export const { initModal, closeModal, clearModal, setMetaData, openModal } = editModalSlice.actions;

export default editModalSlice.reducer;
