import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { GetSystemsResponse, fetchData } from 'api';
import {
  AnalyteGroup,
  Page,
  ScheduleCycle,
  ScheduleQueryOptions,
  ScheduleStatusI,
  System,
} from 'interfaces';
import _isEqual from 'lodash.isequal';
import _uniqWith from 'lodash.uniqwith';
import {
  deleteBenchmark,
  postBenchmarkFile,
  postBenchmarkUrl,
} from 'shared-components/EditModal/editModalSlice';
import { RootState } from 'store';
import { constructErrorMessage } from 'utils';

interface StatusFilterQuery {
  kind: ScheduleQueryOptions.Status;
  value: ScheduleStatusI;
}

type FilterQuery = StatusFilterQuery;

interface AnalyteCycleFilter {
  analyte: AnalyteGroup;
  cycle: ScheduleCycle;
}

interface DashboardPageState {
  isLoading: boolean;
  error: string | null;
  systems: System[];
  isDisplayMap: boolean;
  filterQueries: FilterQuery[];
  filteringStatus: ScheduleStatusI | null;
  analyteCycleFilters: AnalyteCycleFilter[];
  filteringPBCU6M: boolean;
  filteringPBCU3Y: boolean;
  filteringPBCULCRR: boolean;
  textSearch: string;
}

const initialState: DashboardPageState = {
  isLoading: true,
  error: null,
  systems: [],
  isDisplayMap: false,
  filterQueries: [],
  filteringStatus: null,
  analyteCycleFilters: [],
  filteringPBCU6M: false,
  filteringPBCU3Y: false,
  filteringPBCULCRR: false,
  textSearch: '',
};

export const fetchSystems = createAsyncThunk(
  'dashboardPage/fetchSystems',
  async (clientIdentifier: string, { getState, rejectWithValue }) => {
    const {
      textSearch,
      filterQueries,
      analyteCycleFilters,
    } = (getState() as RootState).dashboardPage;

    const params: any = {};
    if (textSearch.length) params.text = textSearch;

    const filterQueryString = Object.entries(
      filterQueries.reduce(
        (acc: any, filter) => ({
          ...acc,
          [filter.kind]: [...(acc[filter.kind] || []), filter.value],
        }),
        {}
      )
    ).map((t: any) => {
      return `${t[0]}:${t[1].join('|')}`;
    });

    const analyteCycleQueryString = Object.entries(
      analyteCycleFilters.reduce(
        (acc: any, filter) => ({
          ...acc,
          [filter.analyte]: [...(acc[filter.analyte] || []), filter.cycle],
        }),
        {}
      )
    ).map((t: any) => {
      return `analyteGroup:${t[0]} AND cycle:${t[1].join('|')}`;
    });

    const queryString = [...filterQueryString, ...analyteCycleQueryString].join(',');
    if (queryString.length) params.filter = queryString;
    try {
      const response = await fetchData(`clients/${clientIdentifier}/systems`, params);
      return response.data as GetSystemsResponse;
    } catch (err) {
      return rejectWithValue(constructErrorMessage((err as { response: unknown }).response));
    }
  }
);

const dashboardPageSlice = createSlice({
  name: 'dashboardPage',
  initialState,
  reducers: {
    setIsDisplayMap(state, action) {
      state.isDisplayMap = action.payload;
    },
    applyTextSearch(state, action) {
      state.textSearch = action.payload;
    },
    // Single Filters
    setOnlyActiveStatusFilter(state) {
      state.filteringPBCU6M = false;
      state.filteringPBCU3Y = false;
      state.filteringPBCULCRR = false;
      state.analyteCycleFilters = [];

      state.filteringStatus = ScheduleStatusI.Active;
      state.filterQueries = [{ kind: ScheduleQueryOptions.Status, value: ScheduleStatusI.Active }];
    },
    setOnlyPBCU6MFilter(state) {
      state.filteringStatus = null;
      state.filterQueries = [{ kind: ScheduleQueryOptions.Status, value: ScheduleStatusI.Active }];

      state.filteringPBCU3Y = false;
      state.filteringPBCULCRR = false;
      state.filteringPBCU6M = true;

      state.analyteCycleFilters = [
        { analyte: AnalyteGroup.PBCU, cycle: ScheduleCycle.SixMonth },
        { analyte: AnalyteGroup.PBCU, cycle: ScheduleCycle.WQP },
      ];
    },
    setOnlyPBCU3YFilter(state) {
      state.filteringStatus = null;
      state.filterQueries = [{ kind: ScheduleQueryOptions.Status, value: ScheduleStatusI.Active }];

      state.filteringPBCU6M = false;
      state.filteringPBCULCRR = false;
      state.filteringPBCU3Y = true;

      state.analyteCycleFilters = [{ analyte: AnalyteGroup.PBCU, cycle: ScheduleCycle.ThreeYear }];
    },
    setOnlyPBCULCRRFilter(state) {
      state.filteringStatus = null;
      state.filterQueries = [{ kind: ScheduleQueryOptions.Status, value: ScheduleStatusI.Active }];

      state.filteringPBCU6M = false;
      state.filteringPBCU3Y = false;
      state.filteringPBCULCRR = true;

      state.analyteCycleFilters = [{ analyte: AnalyteGroup.PBCU, cycle: ScheduleCycle.LCRR }];
    },
    clearAllFilters(state) {
      state.filteringPBCU6M = false;
      state.filteringPBCU3Y = false;
      state.filteringPBCULCRR = false;
      state.analyteCycleFilters = [];

      state.filteringStatus = null;
      state.filterQueries = [];
    },
    // Filters to be used together (not currently being used)
    addStatusFilter(state, action) {
      const { status } = action.payload;
      // First clear out all status filters
      const clearedStatusFilters = state.filterQueries.filter(filterQuery => {
        return (
          filterQuery.value !== ScheduleStatusI.Previous &&
          filterQuery.value !== ScheduleStatusI.Active &&
          filterQuery.value !== ScheduleStatusI.Upcoming
        );
      });
      state.filterQueries = [
        ...clearedStatusFilters,
        { kind: ScheduleQueryOptions.Status, value: status },
      ];
      state.filteringStatus = status;
    },
    removeStatusFilter(state) {
      state.filterQueries = state.filterQueries.filter(filterQuery => {
        return (
          filterQuery.value !== ScheduleStatusI.Previous &&
          filterQuery.value !== ScheduleStatusI.Active &&
          filterQuery.value !== ScheduleStatusI.Upcoming
        );
      });
      state.filteringStatus = null;
    },
    addAnalyteCycleFilter(state, action) {
      const { analyte, cycle } = action.payload;
      const isPBCU6M = analyte === AnalyteGroup.PBCU && cycle === ScheduleCycle.SixMonth;
      const isPBCU3Y = analyte === AnalyteGroup.PBCU && cycle === ScheduleCycle.ThreeYear;
      const isPBCULCRR = analyte === AnalyteGroup.PBCU && cycle === ScheduleCycle.LCRR;
      const filteringPBCU6M = isPBCU6M || state.filteringPBCU6M;
      const filteringPBCU3Y = isPBCU3Y || state.filteringPBCU3Y;
      const filteringPBCULCRR = isPBCULCRR || state.filteringPBCULCRR;
      state.analyteCycleFilters = _uniqWith(
        [...state.analyteCycleFilters, action.payload],
        _isEqual
      );
      state.filteringPBCU6M = filteringPBCU6M;
      state.filteringPBCU3Y = filteringPBCU3Y;
      state.filteringPBCULCRR = filteringPBCULCRR;
    },
    removeAnalyteCycleFilter(state, action) {
      const { analyte, cycle } = action.payload;
      const isPBCU6M = analyte === AnalyteGroup.PBCU && cycle === ScheduleCycle.SixMonth;
      const isPBCU3Y = analyte === AnalyteGroup.PBCU && cycle === ScheduleCycle.ThreeYear;
      const isPBCULCRR = analyte === AnalyteGroup.PBCU && cycle === ScheduleCycle.LCRR;
      state.analyteCycleFilters = state.analyteCycleFilters.filter(
        (f: any) => !(f.analyte === analyte && f.cycle === cycle)
      );
      state.filteringPBCU6M = isPBCU6M ? false : state.filteringPBCU6M;
      state.filteringPBCU3Y = isPBCU3Y ? false : state.filteringPBCU3Y;
      state.filteringPBCULCRR = isPBCULCRR ? false : state.filteringPBCULCRR;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSystems.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchSystems.fulfilled, (state, action) => {
        state.error = null;
        state.systems = action.payload.data;
        state.isLoading = false;
      })
      .addCase(fetchSystems.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addMatcher(
        isAnyOf(postBenchmarkUrl.fulfilled, postBenchmarkFile.fulfilled, deleteBenchmark.fulfilled),
        (state, action) => {
          const { currentPage, system_id, schedule_id } = action.meta.arg;
          if (currentPage !== Page.Dashboard || !state.systems) {
            return state;
          }
          state.systems = state.systems.map(system => {
            if (system._id !== system_id) {
              return system;
            }
            return {
              ...system,
              schedules: system.schedules.map(schedule => {
                if (schedule._id !== schedule_id) {
                  return schedule;
                }
                return { ...schedule, benchmarks: action.payload.data };
              }),
            };
          });
        }
      );
  },
});

export const {
  applyTextSearch,
  clearAllFilters,
  setOnlyActiveStatusFilter,
  setOnlyPBCU6MFilter,
  setOnlyPBCU3YFilter,
  setOnlyPBCULCRRFilter,
  setIsDisplayMap,
} = dashboardPageSlice.actions;

export default dashboardPageSlice.reducer;
