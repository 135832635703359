import DayJSUtils from '@date-io/dayjs';
import { Button, TextField, makeStyles } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Dayjs } from 'dayjs';
import { SampleSite, SampleSiteSubmitKind } from 'interfaces';
import React, { useState } from 'react';

export type PickedUpSubmit = Pick<
  SampleSite,
  'logDate' | 'faucetCollectionDate' | 'faucetLastUsedDate' | 'faucetLocation'
> & { kind: SampleSiteSubmitKind };

interface PickUpFormProps {
  value: number;
  index: number;
  onSubmit: ({
    kind,
    logDate,
    faucetCollectionDate,
    faucetLastUsedDate,
    faucetLocation,
  }: PickedUpSubmit) => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
      '&:first-child': {
        marginTop: theme.spacing(2),
      },
      '&:last-child': {
        marginTop: theme.spacing(3),
      },
    },
  },
}));

export const PickUpForm: React.FC<PickUpFormProps> = ({ value, index, onSubmit }) => {
  const classes = useStyles();
  const [logDate, setLogDate] = useState<Dayjs | null>(null);
  const [faucetCollectionDate, setFaucetCollectionDate] = useState<Dayjs | null>(null);
  const [faucetLastUsedDate, setFaucetLastUsedDate] = useState<Dayjs | null>(null);
  const [faucetLocation, setFaucetLocation] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!logDate || !faucetCollectionDate || !faucetLastUsedDate || !faucetLocation.length) return;
    const newSampleSiteInfo = {
      kind: SampleSiteSubmitKind.PickedUp,
      faucetCollectionDate: faucetCollectionDate.format(),
      faucetLastUsedDate: faucetLastUsedDate.format(),
      faucetLocation,
    };
    onSubmit(newSampleSiteInfo);
  };

  const handleSetFaucetLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFaucetLocation(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
      <div className={classes.container} hidden={value !== index}>
        {value === index && (
          <>
            <MuiPickersUtilsProvider utils={DayJSUtils}>
              <DateTimePicker
                required
                value={logDate}
                disableFuture
                onChange={setLogDate}
                label="Pick Up Time"
                format="MM/DD/YYYY h:mm a"
                showTodayButton
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DayJSUtils}>
              <DateTimePicker
                required
                value={faucetLastUsedDate}
                disableFuture
                onChange={setFaucetLastUsedDate}
                label="Water Last Used"
                format="MM/DD/YYYY h:mm a"
                showTodayButton
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DayJSUtils}>
              <DateTimePicker
                required
                value={faucetCollectionDate}
                disableFuture
                onChange={setFaucetCollectionDate}
                label="Sample Collected"
                format="MM/DD/YYYY h:mm a"
                showTodayButton
              />
            </MuiPickersUtilsProvider>
            <TextField
              onChange={handleSetFaucetLocationChange}
              value={faucetLocation}
              required
              label="Faucet Location"
            />
            <Button
              color="primary"
              variant="contained"
              disabled={
                !logDate || !faucetCollectionDate || !faucetLastUsedDate || !faucetLocation.length
              }
              type="submit">
              Submit
            </Button>
          </>
        )}
      </div>
    </form>
  );
};
