import { Box, Divider } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const SystemSkeleton: React.FC = () => {
  return (
    <Box display="flex" height={{ xs: 244, sm: 178 }}>
      <Box display={{ xs: 'none', md: 'block' }} minWidth={{ xs: 160, md: 240 }} mr={3}>
        <Skeleton variant="rect" height="100%" width="100%" />
      </Box>
      <Box display="flex" flexDirection="column" flex="1 1 100%" justifyContent="space-between">
        <Skeleton variant="rect" width={200} height={32} />
        <Skeleton variant="rect" width={200} height={32} />
        <Skeleton variant="rect" width={300} height={32} />
        <Skeleton variant="rect" height={32} />
        <Box display={{ xs: 'block', sm: 'none' }}>
          <Skeleton variant="rect" height={32} />
        </Box>
      </Box>
    </Box>
  );
};

const SystemListSkeleton: React.FC = () => {
  const emptyArray = [...Array(2)];
  return (
    <Box>
      {emptyArray.map((_, i) => (
        <React.Fragment key={i}>
          <SystemSkeleton />
          {i !== emptyArray.length - 1 && (
            <Box my={4}>
              <Divider />
            </Box>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default SystemListSkeleton;
