import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchData, GetClientsResponse } from 'api';
import { Client } from 'interfaces';
import { constructErrorMessage } from 'utils';

interface ManageClientsState {
  clients: Client[] | null;
  error: string | null;
}
const initialState: ManageClientsState = {
  clients: null,
  error: null,
};

export const fetchClients = createAsyncThunk(
  'manageClientsPage/fetchClients',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchData(`clients`);
      return response.data as GetClientsResponse;
    } catch (err) {
      return rejectWithValue(constructErrorMessage(err.response));
    }
  }
);

const manageClientsPageSlice = createSlice({
  name: 'manageClientsPage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.error = null;
        state.clients = action.payload.data;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});

export default manageClientsPageSlice.reducer;
