import './index.css';

import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { withAuth0Authenticated } from 'shared-components';

const AppWithAuth = withAuth0Authenticated(App);

ReactDOM.render(
  <Auth0Provider
    domain="lja-water-sampling.us.auth0.com"
    clientId="xXb5gGJpGIM4mUjbAYqpJ15orC8FXZ93"
    redirectUri={window.location.origin}>
    <Provider store={store}>
      <AppWithAuth />
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
