import axios from 'axios';
const { REACT_APP_API: baseUrl, REACT_APP_API_KEY: apiKey } = process.env;

export const fetchData = <T = unknown>(route: string, params: any = {}) => {
  const source = axios.CancelToken.source();

  return axios.get<T>(`${baseUrl}/${route}`, {
    params: { code: apiKey, ...params },
    cancelToken: source.token,
  });
};

export const postData = <T = unknown>(
  route: string,
  body: any,
  params: any = {},
  headers: Headers = new Headers()
) => {
  const source = axios.CancelToken.source();

  const headersObj = Object.fromEntries(headers.entries());
  return axios.post<T>(`${baseUrl}/${route}`, body, {
    params: { code: apiKey, ...params },
    cancelToken: source.token,
    headers: headersObj,
  });
};

export const patchData = <T = unknown>(route: string, body: any = {}) => {
  const source = axios.CancelToken.source();

  return axios.patch<T>(`${baseUrl}/${route}`, body, {
    params: { code: apiKey },
    cancelToken: source.token,
  });
};

export const deleteData = <T = unknown>(route: string, params: any = {}) => {
  const source = axios.CancelToken.source();

  return axios.delete<T>(`${baseUrl}/${route}`, {
    params: { code: apiKey, ...params },
    cancelToken: source.token,
  });
};
