import {
  AppBar,
  Backdrop,
  Box,
  Fade,
  IconButton,
  LinearProgress,
  Modal,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { FormKind } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store';

import { clearModal, closeModal } from './editModalSlice';
import {
  CreateBlobForm,
  CreateNewSiteForm,
  CreateUrlForm,
  DeleteBenchmarkForm,
  EditSampleSiteForms,
} from './forms';

const useModalStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxHeight: '100vh',
    overflow: 'auto',
    width: 325,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    '&:focus': {
      outline: 0,
    },
  },
}));

export const EditModal: React.FC = () => {
  const isModalOpen = useSelector((state: RootState) => state.editModal.isModalOpen);
  const classes = useModalStyles();
  const saveStateonClose = useSelector((state: RootState) => state.editModal.saveStateonClose);

  const [isModalContentRendered, setIsModalContentRendered] = useState(false);
  const dispatch = useAppDispatch();

  // Modal "onClose" callback not working as desired, so keeping track with state instead
  useEffect(() => {
    if (!isModalContentRendered && !saveStateonClose) {
      dispatch(clearModal());
    }
  }, [isModalContentRendered, saveStateonClose, dispatch]);

  return (
    <Modal
      className={classes.modal}
      open={isModalOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
      }}>
      <Fade in={isModalOpen}>
        <Box className={classes.paper}>
          <ModalContent setIsRendered={setIsModalContentRendered} />
        </Box>
      </Fade>
    </Modal>
  );
};

interface useModalContentStyleProps {
  isLoading: boolean;
}

const useModalContentStyles = makeStyles((theme: Theme) => ({
  title: {
    flex: '1 1 100%',
  },
  cancelButton: {
    color: theme.palette.background.paper,
  },
  progressBar: {
    visibility: (props: useModalContentStyleProps) => (props.isLoading ? 'visible' : 'hidden'),
  },
}));

interface ModalContentProps {
  setIsRendered: (isRendered: boolean) => void;
}

const ModalContent: React.FC<ModalContentProps> = ({ setIsRendered }) => {
  const dispatch = useAppDispatch();
  const title = useSelector((state: RootState) => state.editModal.title);
  const isLoading = useSelector((state: RootState) => state.editModal.isLoading);
  const classes = useModalContentStyles({ isLoading });

  useEffect(() => {
    setIsRendered(true);
    return () => {
      setIsRendered(false);
    };
  }, [setIsRendered]);

  return (
    <>
      <AppBar elevation={0} position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h5">
            {title && title}
          </Typography>
          <IconButton
            className={classes.cancelButton}
            edge="end"
            onClick={() => dispatch(closeModal({ saveStateonClose: false }))}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box px={2} pb={2}>
        <FormSwitch />
      </Box>
      <LinearProgress className={classes.progressBar} />
    </>
  );
};

const FormSwitch: React.FC = () => {
  const formKind = useSelector((state: RootState) => state.editModal.formKind);

  if (!formKind) {
    return <></>;
  }

  switch (formKind) {
    case FormKind.CreateUrlForm:
      return <CreateUrlForm />;
    case FormKind.CreateBlobForm:
      return <CreateBlobForm />;
    case FormKind.DeleteForm:
      return <DeleteBenchmarkForm />;
    case FormKind.EditSampleSite:
      return <EditSampleSiteForms />;
    case FormKind.CreateNewSite:
      return <CreateNewSiteForm />;
  }
};
