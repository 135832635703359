import {
  AppBar,
  Box,
  BoxProps,
  Button,
  Divider,
  Drawer,
  Theme,
  Toolbar,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import ExpandUpIcon from '@material-ui/icons/ExpandLess';
import ExpandDownIcon from '@material-ui/icons/ExpandMore';
import { SampleSiteFeaturePoint } from 'interfaces';
import { LngLat } from 'mapbox-gl';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AlertMessage, ScheduleStatusCard, ScheduleStatusCardSkeleton } from 'shared-components';
import { openModal, setMetaData } from 'shared-components/EditModal/editModalSlice';
import { RootState, useAppDispatch } from 'store';
import { constructNewSiteFeatureCollection } from 'utils';

import {
  SampleSitesList,
  SampleSitesListSkeleton,
  SystemHeader,
  SystemHeaderSkeleton,
} from './components';
import { FlyToLocation } from './components/Map';
import Map from './components/Map';
import { NewSiteInteractionCard } from './components/NewSiteInteractionCard/NewSiteInteractionCard';
import { fetchSchedule, resetState, setEditMode, setFocusedSampleId } from './mapPageSlice';

const drawerWidth = 375;

interface UseStylesProps {
  matches: boolean;
  isDrawerExpanded: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    height: (props: UseStylesProps) =>
      props.matches ? (props.isDrawerExpanded ? '100%' : 150) : '100%',
    width: (props: UseStylesProps) => (props.matches ? '100vw' : drawerWidth),
    flexShrink: 0,
  },
  drawerPaper: {
    height: (props: UseStylesProps) =>
      props.matches ? (props.isDrawerExpanded ? '100%' : 150) : '100%',
    width: (props: UseStylesProps) => (props.matches ? '100vw' : drawerWidth),
    maxWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    background: theme.palette.background.default,
  },
  expandButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  progressContainer: {
    flexDirection: 'column',
  },
  benchmarkContainer: {
    margin: theme.spacing(2, 0, 0, 0),
    justifyContent: 'space-between',
  },
}));

// Only `flex` is currently needed.
// Include any other props that may be useful to size component to parent.
type MapPageProps = Partial<Pick<BoxProps, 'flex'>>;

const MapPage: React.FC<MapPageProps> = props => {
  const dispatch = useAppDispatch();
  const schedule = useSelector((state: RootState) => state.mapPage.schedule);
  const isLoading = useSelector((state: RootState) => state.mapPage.isLoading);
  const error = useSelector((state: RootState) => state.mapPage.error);
  const systemDetails = useSelector((state: RootState) => state.mapPage.system);
  const sampleSiteFeatureCollection = useSelector(
    (state: RootState) => state.mapPage.sampleSiteFeatureCollection
  );
  const systemBoundaryFeatureCollection = useSelector(
    (state: RootState) => state.mapPage.systemBoundaryFeatureCollection
  );
  const isEditMode = useSelector((state: RootState) => state.mapPage.isEditMode);
  const focusedSampleId = useSelector((state: RootState) => state.mapPage.focusedSampleId);

  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);
  const classes = useStyles({ matches, isDrawerExpanded });

  const [mapFlyToLocation, setMapFlyToLocation] = useState<FlyToLocation>();
  const [newSiteLocation, setNewSiteLocation] = useState<LngLat | null>(null);
  const { system_id, schedule_id } = useParams() as any;

  useEffect((): any => {
    // in case user navigates to dashboard page while trying to set new site location
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!system_id || !schedule_id) return;
    const schedulePromise = dispatch(fetchSchedule({ system_id, schedule_id }));

    return () => {
      schedulePromise.abort();
    };
  }, [dispatch, system_id, schedule_id]);

  const handleOnListSelect = useCallback(
    (site: SampleSiteFeaturePoint) => {
      dispatch(setFocusedSampleId(site.id));

      setMapFlyToLocation({
        center: site.geometry.coordinates as [number, number],
        zoom: 16,
      });
    },
    [dispatch, setMapFlyToLocation]
  );

  const handleOnMapSampleSiteSelect = useCallback(
    (site_id: number | null) => {
      dispatch(setFocusedSampleId(site_id));
    },
    [dispatch]
  );

  const handleOnFinishEditMode = (isConfirm: boolean) => {
    if (newSiteLocation && isConfirm) {
      dispatch(setMetaData({ newLng: newSiteLocation.lng, newLat: newSiteLocation.lat }));
    }
    dispatch(setEditMode(false));
    setNewSiteLocation(null);
    dispatch(openModal());
  };

  const newSiteFeatureCollection = constructNewSiteFeatureCollection(newSiteLocation);

  if (isLoading) {
    return (
      <Box {...props} display="flex" flexDirection={matches ? 'column-reverse' : 'row'}>
        <Drawer
          className={classes.drawer}
          anchor={matches ? 'bottom' : 'left'}
          variant="permanent"
          PaperProps={{ variant: 'outlined' }}
          classes={{
            paper: classes.drawerPaper,
          }}>
          {(!matches || isDrawerExpanded) && <Toolbar />}
          {matches && !isDrawerExpanded && <Divider />}
          <AppBar position="static" color="default" elevation={0}>
            {(!matches || isDrawerExpanded) && (
              <Box mx={2} mb={2} mt={matches ? 1 : 2}>
                <SystemHeaderSkeleton size={matches ? 'sm' : 'md'} />
              </Box>
            )}
            <Divider />
          </AppBar>
          <Box overflow="hidden auto">
            {(!matches || isDrawerExpanded) && (
              <Box p={2}>
                <ScheduleStatusCardSkeleton
                  classes={{
                    progressContainer: classes.progressContainer,
                    benchmarkContainer: classes.benchmarkContainer,
                  }}
                />
              </Box>
            )}
            <Divider />
            <SampleSitesListSkeleton />
          </Box>
        </Drawer>
      </Box>
    );
  }

  if (error) return <AlertMessage severity="error" message={error} />;

  if (
    !systemDetails ||
    !schedule ||
    !sampleSiteFeatureCollection ||
    !systemBoundaryFeatureCollection
  ) {
    return (
      <AlertMessage
        severity="error"
        message={'An unexpected error occured retrieving the schedule'}
      />
    );
  }

  return (
    <Box {...props} display="flex" flexDirection={matches ? 'column-reverse' : 'row'}>
      <Drawer
        className={classes.drawer}
        anchor={matches ? 'bottom' : 'left'}
        variant="permanent"
        PaperProps={{ variant: 'outlined' }}
        classes={{
          paper: classes.drawerPaper,
        }}>
        {(!matches || isDrawerExpanded) && <Toolbar />}
        {matches && !isDrawerExpanded && <Divider />}
        <AppBar position="static" color="default" elevation={0}>
          {matches && (
            <Button
              className={classes.expandButton}
              onClick={() => setIsDrawerExpanded(!isDrawerExpanded)}>
              {isDrawerExpanded ? (
                <ExpandDownIcon fontSize="small" />
              ) : (
                <ExpandUpIcon fontSize="small" />
              )}
            </Button>
          )}
          {(!matches || isDrawerExpanded) && (
            <SystemHeader
              system={systemDetails}
              size={matches ? 'sm' : 'md'}
              mx={2}
              mb={2}
              mt={matches ? 1 : 2}
            />
          )}
          <Divider />
        </AppBar>
        <Box overflow="hidden auto">
          {(!matches || isDrawerExpanded) && (
            <React.Fragment>
              <Box p={2}>
                <ScheduleStatusCard
                  classes={{
                    progressContainer: classes.progressContainer,
                    benchmarkContainer: classes.benchmarkContainer,
                  }}
                  hasMapButton={false}
                  system_id={system_id}
                  schedule={schedule}
                />
              </Box>
              <Divider />
            </React.Fragment>
          )}
          <SampleSitesList
            onListItemSelect={handleOnListSelect}
            focusedListItemId={focusedSampleId}
            system_id={system_id}
            schedule_id={schedule_id}
            systemName={systemDetails.name}
            scheduleCycle={schedule.cycle}
          />
        </Box>
      </Drawer>
      <Box flex="1 1 0%" display="flex" position="relative">
        {isEditMode && (
          <Box position="relative" zIndex={10}>
            <Box position="absolute" top={10} left={10}>
              <NewSiteInteractionCard
                hasSelectedSite={!!newSiteLocation?.lng}
                onCancel={handleOnFinishEditMode.bind(null, false)}
                onConfirm={handleOnFinishEditMode.bind(null, true)}
              />
            </Box>
          </Box>
        )}

        <Map
          style={{ flex: '1 1 0%' }}
          flyToLocation={mapFlyToLocation}
          highlightFeature={focusedSampleId}
          onFeatureSelected={handleOnMapSampleSiteSelect}
          onMapSelected={setNewSiteLocation}
          sampleSiteFeatureCollection={sampleSiteFeatureCollection}
          boundaryFeatureCollection={systemBoundaryFeatureCollection}
          newSiteFeatureCollection={newSiteFeatureCollection}
          isEditMode={isEditMode}
        />
      </Box>
    </Box>
  );
};

export default MapPage;
