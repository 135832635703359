import { Layer } from 'mapbox-gl';

import { LayerProps } from './LayerProps';

const newSiteLayers = (id: string, { theme }: LayerProps): Layer[] => {
  const markers: Layer = {
    id,
    type: 'circle',
    paint: {
      'circle-radius': {
        base: 1.5,
        stops: [
          [4, 4],
          [14, 8],
          [22, 60],
        ],
      },
      'circle-color': theme.palette.status.newSite.main,
      'circle-stroke-width': {
        base: 1.5,
        stops: [
          [8, 1],
          [16, 2],
          [22, 3],
        ],
      },
      'circle-stroke-color': theme.palette.status.newSite.dark,
      'circle-opacity': 0.9,
      'circle-stroke-opacity': 0.9,
    },
    layout: {},
  };

  return [markers];
};

export default newSiteLayers;
