import { Box, Typography } from '@material-ui/core';
import EventNoteIcon from '@material-ui/icons/EventNote';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { ScheduleCycle, System } from 'interfaces';
import React from 'react';
import { ScheduleStatusCard, SystemOverviewHeader } from 'shared-components';
import { formatCycleKind, sortYearlySchedules } from 'utils';

/** System with only props required by SystemListItem */
interface SystemListItemProps {
  system: Pick<
    System,
    '_id' | 'state' | 'systemNumber' | 'name' | 'boundary' | 'externalResources' | 'schedules'
  >;
  currentDate: {
    day: string;
    month: string;
    year: string;
  };
}

export const SystemListItem: React.FC<SystemListItemProps> = ({ system, currentDate }) => {
  const { _id: system_id, schedules } = system;
  dayjs.extend(isBetween);

  const sortedSchedules = [...schedules].sort(sortYearlySchedules);
  const { day, month, year } = currentDate;

  // Find most relevant schedule (highest in list that is in progress), defaults to highest in list
  let mostRelevantSchedule: {
    isValid: boolean;
    mapLink: string | null;
    tooltip: string;
  } = { isValid: false, mapLink: null, tooltip: '' };

  let i = sortedSchedules.length - 1;

  while (i > -1) {
    if (mostRelevantSchedule.isValid) break;

    const { _id, startDate, endDate, cycle, benchmarks } = sortedSchedules[i];

    const isValid = dayjs(`${year}-${month}-${day}`).isBetween(
      `${startDate.year}-${startDate.month}-${startDate.day}`,
      `${endDate.year}-${endDate.month}-${endDate.day}`,
      null,
      '['
    );

    if (isValid || i === sortedSchedules.length - 1) {
      mostRelevantSchedule = {
        isValid,
        mapLink:
          cycle === ScheduleCycle.LCRR
            ? benchmarks.lcrrMap?.url || null
            : `/systems/${system_id}/schedules/${_id}`,
        tooltip: formatCycleKind(cycle, startDate.month),
      };
    }

    i--;
  }

  return (
    <Box display="flex" flexDirection="column">
      <SystemOverviewHeader
        system={system}
        shouldLinkDetails={true}
        mapPath={mostRelevantSchedule.mapLink || undefined}
        mapTooltip={mostRelevantSchedule.tooltip}
      />
      {sortedSchedules.length === 0 && (
        <Box
          color="text.secondary"
          flex="1 1 auto"
          py={4}
          display="flex"
          flexDirection="row"
          alignItems="center">
          <EventNoteIcon />
          <Box ml={1}>
            <Typography>No sampling schedules available</Typography>
          </Box>
        </Box>
      )}
      {sortedSchedules.map(schedule => {
        return (
          <Box key={schedule._id} mt={3}>
            <ScheduleStatusCard system_id={system_id} schedule={schedule} />
          </Box>
        );
      })}
    </Box>
  );
};
