import { Button, Card, CardActions, CardContent, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

interface NewSiteInteractionCardProps {
  hasSelectedSite: boolean;

  onCancel: () => void;
  onConfirm: () => void;
}

type UseStyleProps = Pick<NewSiteInteractionCardProps, 'hasSelectedSite'>;

const useStyles = makeStyles(() => ({
  confirmButton: {
    visibility: (props: UseStyleProps) => (props.hasSelectedSite ? 'visible' : 'hidden'),
  },
}));

export const NewSiteInteractionCard: React.FC<NewSiteInteractionCardProps> = ({
  onCancel,
  onConfirm,
  hasSelectedSite,
}) => {
  const classes = useStyles({ hasSelectedSite });
  return (
    <Card>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Set new site location by clicking on the map
        </Typography>
      </CardContent>
      <CardActions>
        <Button color="secondary" onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button
          className={classes.confirmButton}
          color="primary"
          onClick={onConfirm}
          variant="outlined">
          Confirm
        </Button>
      </CardActions>
    </Card>
  );
};
