import { Asset } from './core';

export enum BenchmarkNames {
  'TCEQSampleSites' = 'TCEQ Sample Sites',
  'SamplesAtLab' = 'Samples at Lab',
  'LabResults' = 'Lab Results',
  'PostedOnDWW' = 'Posted on DWW',
  'LCNProvided' = 'LCN Provided',
  'TCEQVerifiedComplete' = 'TCEQ Verified Complete',
  'LCRRMap' = 'LCRR Map',
  'DetailedInventory' = 'Detailed Inventory',
  'CompletedWithTCEQApproval' = 'Completed with TCEQ Approval',
}

export interface BenchmarkBlob extends Asset {
  completedOn: Date;
  kind: 'blob';
}

export interface BenchmarkUrl {
  url: string;
  completedOn: Date;
  kind: 'url';
}

export type Benchmark = BenchmarkBlob | BenchmarkUrl;
