import Box from '@material-ui/core/Box';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import Typography from '@material-ui/core/Typography';
import { Schedule } from 'interfaces';
import React from 'react';

interface MaterialClassificationProps {
  items: NonNullable<Schedule['materialClassifications']>;
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  inner: {
    borderRadius: theme.shape.borderRadius,
  },
}));

const classficationToPalette = (
  classification: MaterialClassificationProps['items'][0]['classification'],
  theme: Theme
): PaletteColor => {
  const noWhitespace = classification.replace(/[\s-]/g, '');
  const camelCase = noWhitespace.charAt(0).toLowerCase() + noWhitespace.slice(1);
  return (
    theme.palette.lineMaterial[camelCase as keyof Theme['palette']['lineMaterial']] ||
    theme.palette.lineMaterial.other
  );
};

export const MaterialClassification: React.FC<MaterialClassificationProps> = ({ items }) => {
  const classes = useStyles();
  const theme = useTheme();

  const calcPercentage = (count: number) => {
    const total = items.reduce((acc, { count }) => acc + count, 0);
    const percentage = count / total;
    return percentage < 0.01
      ? `${count.toLocaleString()} (1%)`
      : `${count.toLocaleString()} (${percentage.toLocaleString(undefined, {
          style: 'percent',
          minimumFractionDigits: 0,
        })})`;
  };

  return (
    <Box display="flex" flexDirection="row" p="2px" overflow="hidden" className={classes.container}>
      <Box flex={1} display="flex" flexDirection="row" overflow="hidden" className={classes.inner}>
        {[...items]
          .sort((a, b) => b.count - a.count)
          .map(({ classification, count }, index) => {
            const palette = classficationToPalette(classification, theme);
            return (
              <Box
                key={classification}
                flex={[count, '0', '0%'].join(' ')}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                p={1}
                marginRight={index < items.length - 1 ? '1px' : 0}
                style={{ background: palette.main, color: palette.contrastText }}>
                <Typography variant="caption" align="center">
                  {classification}
                </Typography>
                <Typography variant="caption" align="center" style={{ opacity: 0.5 }}>
                  {calcPercentage(count)}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};
