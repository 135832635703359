import geoViewport from '@mapbox/geo-viewport';
import polyline from '@mapbox/polyline';
import { Box, Typography, makeStyles, rgbToHex, useTheme } from '@material-ui/core';
import ImageNotSupportedIcon from '@material-ui/icons/LocationOffOutlined';
import * as turf from '@turf/turf';
import { MultiPolygonGeometry } from 'interfaces';
import React from 'react';
import { Link } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';

const { REACT_APP_MAPBOX_TOKEN: mapboxToken } = process.env;

const mapGeometryToPath = (
  geometry: MultiPolygonGeometry,
  options: { stroke: string; fill: string }
) =>
  geometry
    .map(poly => {
      const polygonFeature = turf.polygon(poly);
      const truncatedPolygonFeature = turf.truncate(polygonFeature, {
        precision: 5,
        coordinates: 2,
      });

      const lineFeature = turf.polygonToLine(truncatedPolygonFeature).geometry as any;

      // If MultiLineString (mulitiple line strings to support holes), only take first line coordinates (outer boundary)
      const coordinates = (lineFeature.type === 'MultiLineString'
        ? lineFeature.coordinates[0]
        : lineFeature.coordinates
      ).map(([lng, lat]: [number, number]) => [lat, lng]);

      const encodedPolyline = polyline.encode(coordinates, 5);

      return `path-1+${options.stroke}-0.3+${options.fill}-0.3(${encodeURIComponent(
        encodedPolyline
      )})`;
    })
    .join(',');

const useStyles = makeStyles(theme => ({
  mapUnavailable: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.text.secondary,
  },
}));

interface MapImageProps {
  overlay: MultiPolygonGeometry;
  path?: string;
}

const InternalExternalLink: React.FC<{ path?: string }> = ({ path, children }) => {
  if (!path) return <>{children}</>;
  if (path.startsWith('http')) return <a href={path}>{children}</a>; // External
  return <Link to={path}>{children}</Link>; // Internal
};

export const StaticOverlayMap: React.FC<MapImageProps> = ({ overlay: polygonOverlay, path }) => {
  const classes = useStyles();

  const theme = useTheme();
  const map = theme.palette.type === 'dark' ? 'dark-v10' : 'streets-v11';
  const fill = rgbToHex(theme.palette.secondary.main).replace('#', '');

  let overlayPath: string;

  try {
    overlayPath = mapGeometryToPath(polygonOverlay, { stroke: fill, fill });
  } catch (err) {
    return (
      <Box className={classes.mapUnavailable}>
        <ImageNotSupportedIcon fontSize="large" color="inherit" />
        <Box mt={1} mx={2}>
          <Typography variant="subtitle1">Map Unavailable</Typography>
        </Box>
      </Box>
    );
  }

  const overlayFeature = turf.multiPolygon(polygonOverlay);
  const bbox = turf.bbox(overlayFeature);
  const bboxAsPolygonFeature = turf.bboxPolygon(bbox);

  // https://github.com/mapbox/geo-viewport uses 256 pixels for calculations,
  // Mapbox tiles are 512 pixels. There's a param for geoViewport.bounds
  // to affect calcualtion but not geoViewport.viewport, so magnified polygon scale by 2
  // prior to calculating for viewport size/zoom
  const polygonFeatureX2 = turf.transformScale(bboxAsPolygonFeature, 2);
  const bboxOfPolygonFeatureX2 = turf.bbox(polygonFeatureX2) as [number, number, number, number];

  return (
    <AutoSizer>
      {({ height, width }) => {
        const calculatedViewport = geoViewport.viewport(bboxOfPolygonFeatureX2, [width, height]);
        // Zoom out 1/2 zoom to include more surrounding area
        const zoom = calculatedViewport.zoom - 0.5;

        const url = new URL(
          [
            'styles/v1/mapbox',
            map,
            'static',
            overlayPath.length < 7000 && overlayPath,
            `${calculatedViewport.center[0]},${calculatedViewport.center[1]},${zoom}`,
            `${width}x${height}@2x`,
          ]
            .filter(Boolean)
            .join('/'),
          'https://api.mapbox.com'
        );
        url.searchParams.append('access_token', mapboxToken || '');
        url.searchParams.append('attribution', 'false');
        url.searchParams.append('logo', 'false');

        return (
          <InternalExternalLink path={path}>
            <img alt="Map" height={height} width={width} src={url.href} />
          </InternalExternalLink>
        );
      }}
    </AutoSizer>
  );
};
