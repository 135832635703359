import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { GetScheduleResponse, fetchData } from 'api';
import {
  BoundaryFeatureCollection,
  Page,
  SampleSitesFeatureCollection,
  Schedule,
  System,
} from 'interfaces';
import {
  deleteBenchmark,
  patchSite,
  postBenchmarkFile,
  postBenchmarkUrl,
  postNewSiteLocation,
  postSampleEvent,
} from 'shared-components/EditModal/editModalSlice';
import { RootState } from 'store';
import {
  constructBoundaryFeatureCollection,
  constructErrorMessage,
  constructSamplesFeatureCollection,
  numberIdFromHex,
} from 'utils';

interface MapPageState {
  isLoading: boolean;
  error: string | null;
  isEditMode: boolean;
  focusedSampleId: number | null;
  schedule?: Schedule;
  system?: System;
  systemBoundaryFeatureCollection?: BoundaryFeatureCollection | null;
  sampleSiteFeatureCollection?: SampleSitesFeatureCollection;
}
const initialState: MapPageState = {
  isLoading: true,
  error: null,
  isEditMode: false,
  focusedSampleId: null,
};

export const fetchSchedule = createAsyncThunk(
  'mapPage/fetchSchedule',
  async ({ system_id, schedule_id }: any, { getState, rejectWithValue }) => {
    const { clientIdentifier } = (getState() as RootState).app;
    try {
      const response = await fetchData(
        `clients/${clientIdentifier}/systems/${system_id}/schedules/${schedule_id}`
      );
      return response.data as GetScheduleResponse;
    } catch (err) {
      return rejectWithValue(constructErrorMessage(err.response));
    }
  }
);

const mapPageSlice = createSlice({
  name: 'mapPage',
  initialState,
  reducers: {
    setEditMode(state, action) {
      state.isEditMode = action.payload;
    },
    setFocusedSampleId(state, action) {
      state.focusedSampleId = action.payload;
    },
    resetState() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSchedule.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchSchedule.fulfilled, (state, action) => {
        state.error = null;
        state.schedule = action.payload.data;
        state.system = action.payload.relationships.system;
        state.systemBoundaryFeatureCollection = action.payload.relationships.system.boundary
          ? constructBoundaryFeatureCollection(action.payload.relationships.system.boundary)
          : null;
        state.sampleSiteFeatureCollection = constructSamplesFeatureCollection(action.payload.data);
        state.isLoading = false;
      })
      .addCase(fetchSchedule.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(postNewSiteLocation.fulfilled, (state, action) => {
        state.schedule = action.payload.data;
        state.sampleSiteFeatureCollection = constructSamplesFeatureCollection(action.payload.data);
        state.focusedSampleId = numberIdFromHex(action.payload.meta.newSiteId);
      })
      .addMatcher(isAnyOf(patchSite.fulfilled, postSampleEvent.fulfilled), (state, action) => {
        state.schedule = action.payload.data;
        state.sampleSiteFeatureCollection = constructSamplesFeatureCollection(action.payload.data);
      })
      .addMatcher(
        isAnyOf(postBenchmarkUrl.fulfilled, postBenchmarkFile.fulfilled, deleteBenchmark.fulfilled),
        (state, action) => {
          if (action.meta.arg.currentPage !== Page.Map || !state.schedule) {
            return state;
          }
          state.schedule.benchmarks = action.payload.data;
        }
      );
  },
});

export const { setEditMode, setFocusedSampleId, resetState } = mapPageSlice.actions;

export default mapPageSlice.reducer;
