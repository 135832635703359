import {
  Box,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { AlertMessage } from 'shared-components';
import { RootState, useAppDispatch } from 'store';

import ManageSystemsForm from './ManageSystemsForm';
import { addNewSystem, fetchSystems, resetState } from './manageSystemsPageSlice';

const ManageSystemsPage: React.FC = () => {
  const { clientIdentifier } = useParams<{ clientIdentifier: string }>();
  const dispatch = useAppDispatch();
  const systems = useSelector((state: RootState) => state.manageSystemsPage.systems);
  const error = useSelector((state: RootState) => state.manageSystemsPage.error);
  const isLoading = useSelector((state: RootState) => state.manageSystemsPage.isLoading);

  useEffect(() => {
    const systemsPromise = dispatch(fetchSystems(clientIdentifier));
    return () => {
      systemsPromise.abort();
      dispatch(resetState());
    };
  }, [dispatch, clientIdentifier]);

  const handleSubmit = async (newSystemName: string, newSystemNumber: string) => {
    await dispatch(
      addNewSystem({ name: newSystemName, systemNumber: newSystemNumber, clientIdentifier })
    );
  };

  if (error) {
    return <AlertMessage severity="error" message={error} />;
  }

  if (isLoading) {
    return (
      <Box py={3}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box py={3}>
      <Box py={1}>
        <Typography variant="h3">{clientIdentifier}</Typography>
      </Box>
      <ManageSystemsForm onSubmit={handleSubmit} />
      <List>
        {systems.map(system => {
          return (
            <Link
              key={system._id}
              component={RouterLink}
              to={`/manage/${clientIdentifier}/systems/${system._id}`}
              variant="subtitle1">
              <ListItem key={system._id} button>
                <ListItemText primary={system.name} secondary={!system.isReady && 'Not Ready'} />
                <ListItemText primary={`# Schedules: ${system.schedules.length}`} />
                {!system?.boundary?.length && (
                  <Alert severity="warning" variant="outlined">
                    missing boundary
                  </Alert>
                )}
              </ListItem>
            </Link>
          );
        })}
      </List>
    </Box>
  );
};

export default ManageSystemsPage;
