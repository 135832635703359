import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetClientResponse, GetClientsResponse, fetchData } from 'api';
import { Client } from 'interfaces';
import { RootState } from 'store';

const CURRENT_SUB_CLIENT_STORAGE_KEY = 'currentSubClient';

interface AppBarState {
  isLoading: boolean;
  client?: Client;
  subClients?: Client[];
  currentSubClient?: Client;
}

const initialState: AppBarState = {
  isLoading: true,
  currentSubClient: window.localStorage.getItem(CURRENT_SUB_CLIENT_STORAGE_KEY)
    ? JSON.parse(window.localStorage.getItem(CURRENT_SUB_CLIENT_STORAGE_KEY) as string)
    : null,
};

export const fetchClient = createAsyncThunk('appBar/fetchClient', async (_, { getState }) => {
  const { clientIdentifier } = (getState() as RootState).app;
  const response = await fetchData(`clients/${clientIdentifier}`);
  return response.data as GetClientResponse;
});

export const fetchSubClients = createAsyncThunk('appBar/fetchSubClients', async () => {
  const response = await fetchData('clients');
  return response.data as GetClientsResponse;
});

export const changeCurrentSubClient = createAction<string>('appBar/changeSubClient');

const appBarSlice = createSlice({
  name: 'appBar',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchClient.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchClient.fulfilled, (state, action) => {
        state.client = action.payload.data;
        state.isLoading = false;
      })
      .addCase(fetchSubClients.fulfilled, (state, action) => {
        const clients = action.payload.data
          .filter(client => client.identifier.toLowerCase() !== 'lja')
          .sort((a, b) => a.name.localeCompare(b.name));

        state.subClients = clients;

        if (!window.localStorage.getItem(CURRENT_SUB_CLIENT_STORAGE_KEY)) {
          state.currentSubClient = clients[0];
          window.localStorage.setItem(CURRENT_SUB_CLIENT_STORAGE_KEY, JSON.stringify(clients[0]));
        }
      })
      .addCase(changeCurrentSubClient, (state, action) => {
        if (!state.subClients) {
          return state;
        }

        const client = state.subClients.find(c => c.identifier === action.payload);
        state.currentSubClient = client;
        window.localStorage.setItem(CURRENT_SUB_CLIENT_STORAGE_KEY, JSON.stringify(client));
      });
  },
});

export default appBarSlice.reducer;
