import {
  amber,
  blue,
  deepOrange,
  green,
  grey,
  orange,
  red,
  teal,
  yellow,
} from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import createSpacing from '@material-ui/core/styles/createSpacing';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    highlight: PaletteColor;
    progress: {
      complete: PaletteColor;
      droppedOff: PaletteColor;
    };
    status: {
      approvedSite: PaletteColor;
      droppedOffSample: PaletteColor;
      pickedUpSample: PaletteColor;
      doNotSample: PaletteColor;
      newSite: PaletteColor;
    };
    lineMaterial: {
      lead: PaletteColor;
      galvanizedRequiringReplacement: PaletteColor;
      nonLead: PaletteColor;
      unknown: PaletteColor;
      notRecorded: PaletteColor;
      other: PaletteColor;
    };
  }
  interface PaletteOptions {
    highlight: PaletteColorOptions;
    progress: {
      complete: PaletteColorOptions;
      droppedOff: PaletteColorOptions;
    };
    status: {
      approvedSite: PaletteColorOptions;
      droppedOffSample: PaletteColorOptions;
      pickedUpSample: PaletteColorOptions;
      doNotSample: PaletteColorOptions;
      newSite: PaletteColorOptions;
    };
    lineMaterial: {
      lead: PaletteColorOptions;
      galvanizedRequiringReplacement: PaletteColorOptions;
      nonLead: PaletteColorOptions;
      unknown: PaletteColorOptions;
      notRecorded: PaletteColorOptions;
      other: PaletteColorOptions;
    };
  }
}

const theme = createMuiTheme({
  overrides: {
    MuiAvatar: {
      root: {
        fontSize: '1rem',
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: 16,
        },
        body: {
          'overscroll-behavior-y': 'none',
          fontSize: '1rem',
        },
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:hover, &:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: createSpacing(6)(1, 1.5),
        fontSize: `${12 / 16}rem`,
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiContainer: {
      maxWidth: 'md',
    },
    MuiLink: {
      underline: 'none',
    },
    MuiMenu: {
      elevation: 2,
    },
    MuiTooltip: {
      enterDelay: 700,
    },
  },
  palette: {
    highlight: {
      light: teal['200'],
      main: teal['A200'],
      dark: teal['A700'],
      contrastText: teal['A700'],
    },
    progress: {
      complete: { main: green['400'] },
      droppedOff: { light: amber['200'], main: amber['400'] },
    },
    status: {
      approvedSite: {
        light: '#6ec6ff',
        main: '#2196f3',
        dark: '#1565c0',
        contrastText: '#ffffff',
      },
      droppedOffSample: {
        light: '#ffff72',
        main: '#ffeb3b',
        dark: '#c8b900',
        contrastText: '#c8b900',
      },
      pickedUpSample: {
        light: '#80e27e',
        main: '#4caf50',
        dark: '#2e7d32',
        contrastText: '#ffffff',
      },
      doNotSample: {
        light: '#8eacbb',
        main: '#607d8b',
        dark: '#37474f',
        contrastText: '#ffffff',
      },
      newSite: {
        light: orange['100'],
        main: orange['A700'],
        dark: orange['900'],
        contrastText: '#ffffff',
      },
    },
    lineMaterial: {
      lead: {
        main: '#AEDDDE',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      galvanizedRequiringReplacement: {
        main: '#F8A3A0',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      nonLead: {
        main: '#B2D6B7',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      unknown: {
        main: '#E0E0E0',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      notRecorded: {
        main: '#E0E0E0',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      other: {
        main: '#E0E0E0',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
    },
    text: {
      secondary: grey['500'],
    },
    primary: {
      light: blue['100'],
      main: blue['500'],
      dark: blue['700'],
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: deepOrange['100'],
      main: deepOrange['A700'],
      dark: deepOrange['900'],
      contrastText: '#FFFFFF',
    },
    success: {
      light: green['300'],
      main: green['700'],
      dark: green['800'],
      contrastText: '#FFFFFF',
    },
    info: {
      light: yellow['300'],
      main: yellow['700'],
      dark: yellow['900'],
      contrastText: '#FFFFFF',
    },
    error: {
      light: red['300'],
      main: red['700'],
      dark: red['900'],
      contrastText: '#FFFFFF',
    },
  },
  shape: {
    borderRadius: 2,
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 14,
    h1: {
      fontSize: `${26 / 16}rem`,
      fontWeight: 400,
    },
    h2: {
      fontSize: `${22 / 16}rem`,
    },
    h3: {
      fontSize: `${20 / 16}rem`,
    },
    h4: {
      fontSize: `${18 / 16}rem`,
      fontWeight: 400,
    },
    h5: {
      fontSize: `${16 / 16}rem`,
    },
    h6: {
      fontSize: `${15 / 16}rem`,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: `${14 / 16}rem`,
      fontWeight: 400,
      lineHeight: 2,
    },
    subtitle2: {
      fontSize: `${13 / 16}rem`,
      fontWeight: 400,
      lineHeight: 2,
    },
    body1: {
      fontSize: `${14 / 16}rem`,
    },
    body2: {
      fontSize: `${14 / 16}rem`,
    },
    button: {
      fontSize: `${14 / 16}rem`,
    },
    caption: {
      fontSize: `${12 / 16}rem`,
    },
  },
});

export default theme;
