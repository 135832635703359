import { BoxProps } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AlertMessage } from 'shared-components';
import { RootState, useAppDispatch } from 'store';

import { SystemDetails, SystemDetailsSkeleton } from './components';
import { fetchSystemDetails } from './detailsPageSlice';

const DetailsPage: React.FC<BoxProps> = props => {
  const { system_id } = useParams<{ system_id: string }>();
  const dispatch = useAppDispatch();
  const isLoading = useSelector((state: RootState) => state.detailsPage.isLoading);
  const error = useSelector((state: RootState) => state.detailsPage.error);
  const system = useSelector((state: RootState) => state.detailsPage.system);

  useEffect(() => {
    if (!system_id) return;
    const systemPromise = dispatch(fetchSystemDetails(system_id));

    return () => {
      systemPromise.abort();
    };
  }, [dispatch, system_id]);

  if (isLoading) return <SystemDetailsSkeleton />;

  if (error) return <AlertMessage severity="error" message={error} />;

  if (!system) {
    return (
      <AlertMessage
        severity="error"
        message={'An unexpected error occured retrieving the system'}
      />
    );
  }

  return <SystemDetails system={system} {...props} />;
};

export default DetailsPage;
