import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const SampleSitesListItemSkeleton: React.FC = () => {
  return (
    <Box px={2} py={1} display="flex" alignItems="center" flexDirection="row" height={70}>
      <Box flex="0 0 auto">
        <Skeleton variant="circle" width={40} height={40} />
      </Box>
      <Box pl={2} flex="1 1 auto">
        <Skeleton variant="rect" height={28} width="100%" />
      </Box>
    </Box>
  );
};

export const SampleSitesListSkeleton: React.FC = () => {
  const arr = new Array(2).fill(true);
  return (
    <>
      {arr.map((_r, i) => {
        return <SampleSitesListItemSkeleton key={i} />;
      })}
    </>
  );
};
