import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetSystemResponse, GetSystemsResponse } from 'api';
import { fetchData, postData } from 'api/services';
import { System } from 'interfaces/System';
import { constructErrorMessage } from 'utils/constructErrorMessage';

interface ManageSystemsState {
  systems: System[];
  isLoading: boolean;
  error: string | null;
  isAddingNewSysytem: boolean;
}

const initialState: ManageSystemsState = {
  error: null,
  isLoading: true,
  systems: [],
  isAddingNewSysytem: false,
};

export const fetchSystems = createAsyncThunk(
  'manageSystemsPage/fetchSystems',
  async (clientIdentifier: string, { rejectWithValue }) => {
    try {
      const response = await fetchData(`clients/${clientIdentifier}/systems`);
      return response.data as GetSystemsResponse;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(constructErrorMessage(error.response));
    }
  }
);

export const addNewSystem = createAsyncThunk(
  'manageSystemsPage/addNewSystem',
  async (
    {
      name,
      systemNumber,
      clientIdentifier,
    }: { name: string; systemNumber: string; clientIdentifier: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await postData(`manage/system`, null, {
        name,
        systemNumber,
        clientIdentifier,
      });
      return response.data as GetSystemResponse;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(constructErrorMessage(error.response));
    }
  }
);

const manageSystemsPageSlice = createSlice({
  name: 'manageSystemsPage',
  initialState,
  reducers: {
    resetState: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSystems.fulfilled, (state, action) => {
        state.systems = action.payload.data;
        state.isLoading = false;
      })
      .addCase(fetchSystems.rejected, (state, action: any) => {
        state.error = action.payload;
      })
      .addCase(addNewSystem.pending, state => {
        state.isAddingNewSysytem = true;
      })
      .addCase(addNewSystem.fulfilled, (state, action) => {
        state.systems = [action.payload.data, ...state.systems];
        state.isAddingNewSysytem = false;
      });
  },
});

export const { resetState } = manageSystemsPageSlice.actions;

export default manageSystemsPageSlice.reducer;
