import { Box, Divider, Typography } from '@material-ui/core';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { BoundaryFeatureCollection } from 'interfaces';
import { fetchSystems } from 'pages/dashboard/dashboardPageSlice';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AlertMessage } from 'shared-components';
import { RootState, useAppDispatch } from 'store';
import { constructBoundaryFeatureCollections } from 'utils';
import { useQueryParams } from 'utils/useQueryParams';

import { Map, SystemListItem } from './components';
import SystemListSkeleton from './SystemListSkeleton';

export const SystemList: React.FC = () => {
  const dispatch = useAppDispatch();
  // Used to check Systems that aren't ready to be viewed by users
  // by passing in query param "?isReady=true"
  const isReadyOverride = useQueryParams().get('isReady') === 'true';
  const systems = useSelector((state: RootState) => state.dashboardPage.systems);
  const isLoading = useSelector((state: RootState) => state.dashboardPage.isLoading);
  const error = useSelector((state: RootState) => state.dashboardPage.error);

  const isDisplayMap = useSelector((state: RootState) => state.dashboardPage.isDisplayMap);
  const textSearch = useSelector((state: RootState) => state.dashboardPage.textSearch);
  const filterQueries = useSelector((state: RootState) => state.dashboardPage.filterQueries);
  const analyteCycleFilters = useSelector(
    (state: RootState) => state.dashboardPage.analyteCycleFilters
  );

  const clientIdentifier = useSelector((state: RootState) => state.app.clientIdentifier);
  const currentSubClient = useSelector((state: RootState) => state.appBar.currentSubClient);

  useEffect(() => {
    if (!clientIdentifier) return;

    let systemPromise: ReturnType<AsyncThunkAction<any, any, any>> | null = null;

    if (clientIdentifier === 'lja' && currentSubClient) {
      systemPromise = dispatch(fetchSystems(currentSubClient.identifier));
    } else if (clientIdentifier !== 'lja') {
      systemPromise = dispatch(fetchSystems(clientIdentifier));
    }

    return () => {
      systemPromise && systemPromise.abort();
    };
  }, [
    dispatch,
    textSearch,
    filterQueries,
    analyteCycleFilters,
    clientIdentifier,
    currentSubClient,
  ]);

  if (isLoading && !isDisplayMap) return <SystemListSkeleton />;

  if (error) return <AlertMessage severity="error" message={error} />;

  if (!systems) {
    return (
      <AlertMessage
        severity="error"
        message={'An unexpected error occured retrieving the systems'}
      />
    );
  }

  if (systems.length === 0 && !isDisplayMap) {
    return <AlertMessage severity="info" message={'No systems matching the current filter'} />;
  }

  const dayJsDate = dayjs();
  const currentDate = {
    day: dayJsDate.format('D'),
    month: dayJsDate.format('M'),
    year: dayJsDate.format('YYYY'),
  };

  const boundaries: BoundaryFeatureCollection = systems
    ? constructBoundaryFeatureCollections(systems)
    : {
        type: 'FeatureCollection',
        features: [],
      };

  if (!isDisplayMap) {
    return (
      <>
        {systems.map((system, i) => {
          return (
            <React.Fragment key={system._id}>
              {/* Used to check what Systems that are not yet ready to be displayed look like (when overriden) */}
              {!system.isReady && isReadyOverride && (
                <Box display="flex" justifyContent="center" m={6}>
                  <Typography color="error" variant="h5" component="h5">
                    Not Ready For Display
                  </Typography>
                </Box>
              )}
              {(system.isReady || isReadyOverride) && (
                <>
                  <SystemListItem system={system} currentDate={currentDate} />
                  {i !== systems.length - 1 && (
                    <Box my={4}>
                      <Divider />
                    </Box>
                  )}
                </>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  }

  return (
    <Box height={'calc(100vh - 220px)'}>
      <Map boundaryFeatureCollection={boundaries} style={{ height: '100%' }} />
    </Box>
  );
};
